<template>
  <div class="background">

    <div class="loading_global" v-if="loading">
      <span class="loader"></span>
    </div>



    <div v-else>
      <section v-if="!sucesso" class="container">
        <MenuMobile />

        <div class="container box-voltar">
          <div class="voltar">
            <button type="button" class="anterior" @click="voltar">
              <img src="@/assets/img/left.png" alt="icone seta para esquerda" class="seta" />
            </button>
            <p><span>Cadastro de produto </span> <br />Home</p>
          </div>
        </div>

        <div class="form-produto" data-anima="top">
          <form @submit.prevent="cadastrarProduto">
            <div v-if="etapa === 1">
              <div class="espaco"></div>
              <div class="linha-etapa etapa-um"></div>
              <div class="linha-cinza"></div>
              <div class="linha-etapa etapa-um"></div>

              <div class="voltar">
                <p><span>Etapa 1 de 2</span> <br />Cadastrar Produto</p>
              </div>

              <label>CATEGORIAS</label>
              <div class="categoria">
                <select name="select" v-model="categoriaSelecionada">
                  <option v-for="(categoria, index) in categorias" :key="index" :value="categoria.categoria">{{
                    categoria.categoria }}</option>
                </select>
              </div>
              <label>PRODUTOS</label>
              <div class="subcategoria">
                <select name="select" v-model="subcategoriaSelecionada">
                  <option :value="null">TODOS</option>
                  <option v-for="(subcategoria, index) in subcategorias" :key="index" :value="subcategoria">{{
                    subcategoria }}</option>
                </select>
              </div>

              <div class="" style="display: flex; flex-direction: column; align-items: center;" v-if="loadingbeneficio">
                <span class="loader"></span>
                <span>Carregando produtos</span>
              </div>

              <div v-else class="imagem-produtos d-flex justify-content-start">
                <div class="card-prod" v-for="(produto, index) in produtosFiltrados" :key="index"
                  v-on:click="avancar(produto.id)">
                  <img
                    :src="produto.imgAlternativa ? produto.imgAlternativa : 'https://emailimagesnb-v2.s3.sa-east-1.amazonaws.com/oink/Stanley/IMG_PROD_GENERICO.png'"
                    :alt="produto.nome" />
                  <!--<img :src="produto.imgAlternativa" :alt="produto.nome" @mouseover="trocarImagem(produto)" @mouseout="restaurarImagem(produto)" />-->
                  <p>{{ produto.nome }}</p>
                </div>
              </div>
            </div>

            <div v-if="etapa === 2" data-anima="left">
              <div class="espaco"></div>
              <div class="linha-etapa etapa-tres"></div>
              <div class="linha-cinza"></div>
              <div class="linha-etapa etapa-tres"></div>
              <div class="voltar">
                <button type="button" v-if="etapa > 1" v-on:click="anteriorEtapa" class="anterior">
                  <img src="@/assets/img/left.png" alt="icone seta para esquerda" class="seta" />
                </button>
                <p><span>Etapa 2 de 2</span> <br />Cadastrar Produto</p>
              </div>

              <div class="box-revisao">
                <div class="d-flex ultima-etapa justify-content-start">
                  <img :src="produtoUsuario.imgAlternativa" alt="Imagem do produto" class="img-selecionada" />
                  <p>{{ produtoUsuario.nome }}</p>
                </div>

                <label>Qual o local de compra?</label>
                <div class="categoria">
                  <select name="select" v-model="local">
                    <option value="" selected>Selecione</option>
                    <option value="Loja Física Stanley">Loja Física Stanley</option>
                    <option value="Amazon">Amazon</option>
                    <option value="Mercado Livre">Mercado livre</option>
                    <option value="Americanas">Americanas</option>
                    <option value="Submarino">Submarino</option>
                    <option value="Magalu">Magalu</option>
                    <option value="Ganhei de presente">Ganhei de presente</option>
                    <option value="Nao Lembro">Não lembro</option>
                    <option value="Outro">Outro</option>
                  </select>

                  <label v-if="local === 'Outro'">Descreva o local</label>
                  <input type="text" v-if="local === 'Outro'" v-model="outroLocal" class="outro">
                </div>
              </div>

              <button class="btn-avancar">
                FINALIZAR
              </button>
            </div>

          </form>

        </div>

      </section>

      <section class="sucesso" v-else>
        <img src="@/assets/img/logo_legionarios_branca.svg" alt="Logo Stanley Legionários" class="logo" />

        <div class="conteudo-sucesso" data-anima="bottom">
          <h1>PRODUTO CADASTRADO COM <strong>SUCESSO!</strong></h1>
          <p>Você pode ver seu novo produto cadastrado em "Minha Coleção".</p>

          <router-link to="/home" style="text-decoration: none;">
            <button type="button" class="btn-login">IR PARA A HOME</button>
          </router-link>
        </div>
      </section>
    </div>
    <TheFooter />
  </div>
</template>

<script>
import { api } from "@/services.js";
import TheFooter from "@/components/Base/TheFooter.vue";
import MenuMobile from "@/components/Base/MenuMobile.vue";
export default {
  name: "AlterarEmail",
  components: {
    MenuMobile,
    TheFooter
  },

  data() {
    return {
      loadingbeneficio: false,
      etapa: 1,
      totalEtapas: 4,
      loading: true,
      currentRoute: '',
      produtos: [],
      categorias: [],
      categoriaSelecionada: '',
      subcategoriaSelecionada: "",
      subcategorias: [],
      produtoUsuario: [],
      local: "",
      presente: false,
      token: "",
      sucesso: false,
      outroLocal: "",
      items: [
        {
          img: "",
        },
      ]
    }
  },
  created() {
    this.currentRoute = this.$route.path;
  },
  computed: {
    produtosFiltrados: function () {
      if (!this.categoriaSelecionada) {
        return this.produtos;
      } else if (!this.subcategoriaSelecionada) {
        return this.produtos.filter(produto => produto.categoria === this.categoriaSelecionada);
      } else {
        return this.produtos.filter(produto => produto.categoria === this.categoriaSelecionada && produto.subcategoria === this.subcategoriaSelecionada);
      }
    },
  },
  methods: {
    voltar() {
      this.$router.push("/home")
    },
    proximaEtapa() {
      if (this.etapa < this.totalEtapas) {
        this.etapa++;
      }
    },
    anteriorEtapa() {
      if (this.etapa > 1) {
        this.etapa--;
      }
    },
    avancar(id) {
      this.produtoSelecionado(id);
      this.proximaEtapa();

    },
    getImgPath(path) {
      return require(`@/assets/img/${path}`);
    },
    atualizarSubcategorias() {
      const categoriaSelecionada = this.categorias.find(categoria => categoria.categoria === this.categoriaSelecionada);

      if (categoriaSelecionada) {
        this.subcategorias = categoriaSelecionada.subcategorias;
      } else {
        this.subcategorias = [];
      }
    },
    /*trocarImagem(produto) {
        produto.imgOriginal = produto.img; 
        produto.img = produto.imgAlternativa; 
    },
    restaurarImagem(produto) {
        produto.img = produto.imgOriginal; 
    },*/
    produtoSelecionado(id) {
      this.loading = true;
      api
        .get(`/produto/${id}`)
        .then((resp) => {
          this.produtoUsuario = resp.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    buscarProdutos() {
      this.loadingbeneficio = true
      this.loading = true;
      api
        .get('/produto/?page=1&pageSize=1000')
        .then((resp) => {
          this.produtos = resp.data.items;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
          this.loadingbeneficio = false
        });
    },

    buscarCategorias() {
      this.loading = true;
      api
        .get('/produto/categoria')
        .then((resp) => {
          this.categorias = resp.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    cadastrarProduto() {
      this.loading = true;
      this.token = window.localStorage.token;

      if (this.local == 'Ganhei de presente') {
        this.presente = true;
      } else if (this.local == 'Outro') {
        this.local = this.outroLocal;
      }

      const config = {
        headers: {
          'Authorization': `Bearer ${this.token}`
        }
      };

      const data = {
        idProduto: this.produtoUsuario.id,
        localCompra: this.local,
        presente: this.presente
      };

      api
        .post("/produto/cliente", data, config)
        .then((response) => {
          console.log(response.data);
          this.sucesso = true;
        })
        .catch((error) => {
          console.error(error);

        })
        .finally(() => {
          this.loading = false;
        });

    }
  },
  watch: {
    categoriaSelecionada: {
      handler() {
        this.atualizarSubcategorias();
      },
      deep: true
    }
  },
  mounted() {
    this.buscarProdutos();
    this.buscarCategorias();
  }
};
</script>

<style scoped>
.box-revisao label {
  color: #232527;
  font-size: 19px;
  font-family: "klavika bold";
}

.outro {
  margin-bottom: 20px;
}

.txthome {
  text-align: center;
}

.txtcadastrado {
  margin-bottom: 0px;
  font-family: "klavika-web", sans-serif;
  font-weight: 900;
  font-size: 25px;
  color: #000;
  text-align: center;
  text-transform: uppercase;
  margin-top: 100px;
}

.form-produto {
  margin-bottom: 50px !important;
  width: 600px;
  margin: 0 auto;
}

.imagem-produtos {
  overflow: auto;
  flex-wrap: wrap;
  margin-bottom: 50px;
  gap: 7px;
  height: 220px;
}

.box-voltar {
  padding: 0px;
}

.btn-personalizar {
  margin-top: 50px !important;
  transition: background-color 0.3s ease;
  background-color: #00abc8;
  color: white;
  font-size: 20px;
  box-shadow: 2px 3px 9px #00000033;
  cursor: pointer;
  padding: 10px 50px;
  letter-spacing: 0.36px;
  font-family: "klavika-web", sans-serif;
  margin: 0 auto;
  display: table;
}

.btn-personalizar:hover {
  background-color: #00b6d5;
}

.ultima-etapa p {
  margin-bottom: 0px;
  font-family: "klavika bold";
  color: #232527;
  font-size: 25px;
  line-height: 28px;
}

.ultima-etapa {
  margin-bottom: 20px;
  gap: 20px;
  align-items: center;
}

.img-selecionada {
  width: 140px;
}

.produtos-selecionados {
  overflow: auto;
  height: 440px;
  flex-wrap: wrap;
  margin-bottom: 50px;
}

.produtos-selecionados .card-prod {
  padding: 10px;
  margin-right: 2px;
  width: 185px;
  margin-bottom: 10px;
  border: 2px solid #BFBFBF;
}

.produtos-selecionados .card-prod img {
  width: 140px;
  margin: 0 auto;
  padding-bottom: 10px;
}

.card-prod p {
  font-family: "klavika medium";
  color: #52575A;
  font-size: 12px;
  text-transform: uppercase;
  margin-bottom: 0px;
  text-align: center;
}

.card-prod img {
  margin-bottom: 10px;
  width: 85px;
  max-height: 90px;
  margin: 0 auto;
}

.card-prod {
  cursor: pointer;
  padding: 10px 10px 15px 10px;
  margin-right: 2px;
  width: 140px;
  margin-bottom: 10px;
  border: 2px solid #BFBFBF;
}

.card-prod:hover {
  border: 2px solid #00b6d5;
}

.txt-prod-selecionado {
  color: #232527;
  font-size: 22px;
  font-family: "klavika medium";
}

.categoria select,
.subcategoria select {
  color: #52575A;
  font-family: "klavika light";
  text-transform: uppercase;
  margin-bottom: 20px;
  padding: 15px 10px !important;
}

.anterior {
  background-color: transparent;
  border: none;
}

.seta {
  width: 14px;
  margin-right: 15px;
  cursor: pointer;
}

.voltar {
  display: flex;
  margin: 15px 0px 30px 0px;
}

.voltar span {
  color: #52575a;
  font-weight: 200;
}

.voltar p {
  margin-bottom: 0px;
  line-height: 17px;
  color: #232527;
  font-size: 14px;
  font-family: "klavika-web", sans-serif;
  font-weight: 700;
}

.txt-alterado {
  padding-top: 50px;
  width: 500px;
  margin: 0 auto;
  text-align: center;
}

.logo-rodape {
  margin: 0 auto;
  padding-top: 80px;
}

.copyright {
  margin-top: 20px;
}

.btn-avancar {
  transition: background-color 0.3s ease;
  background-color: #00abc8;
  color: white;
  font-size: 16px;
  border: none;
  box-shadow: 2px 3px 9px #00000033;
  cursor: pointer;
  width: 600px;
  height: 48px;
  margin-bottom: 30px !important;
}

.btn-avancar:hover {
  background-color: #00b6d5;
}

input {
  padding: 5px;
}

.linha-cinza {
  height: 1px;
  width: 600px;
  background-color: #a2a2a2;
}

.linha-etapa {
  height: 1px;
}

.etapa-um {
  width: 300px;
  background-color: #00abc8;
}

.etapa-tres {
  width: 600px;
  background-color: #00abc8;
}

form {
  margin-top: 50px;
}


.sucesso {
  height: 100vh;
  background-color: #D4D1C4;
}

.sucesso h1 {
  color: #FFFFFF;
  font-size: 32px;
  font-family: "klavika-web-display", sans-serif;
  font-weight: 200;
  line-height: 35px;
  margin-bottom: 20px;
}

.sucesso strong {
  color: #FFFFFF;
  font-family: "klavika-web", sans-serif;
  font-weight: 700;
}

.sucesso p {
  color: #FFFFFF;
  font-size: 16px;
  font-family: "klavika-web", sans-serif;
  font-weight: 400;
}

.conteudo-sucesso {
  width: 350px;
  margin: 0 auto;
  padding-top: 120px;
}

.btn-login {
  transition: background-color 0.3s ease;
  background-color: #00ABC8;
  color: white;
  font-size: 16px;
  border: none;
  box-shadow: 2px 3px 9px #00000033;
  cursor: pointer;
  width: 350px;
  height: 48px;
  margin: 30px auto;
  display: block;
}

.btn-login:hover {
  background-color: #00b6d5;
}

.sucesso {
  background-image: url('@/assets/img/cadastro_prod_desk.jpg');
  background-size: cover;
  background-repeat: no-repeat;
}

@media (max-width: 990px) {

  .conteudo-sucesso {
    padding: 0px 20px;
    width: 100%;
    position: absolute;
    bottom: 0px;
  }

  .btn-login {
    width: 100%;
  }

  .sucesso {
    background-image: url('@/assets/img/cadastro_prod_mob.jpg');

  }

  .logo-mobile {
    width: 150px;
  }

  .copyright {
    margin-bottom: 30px;
  }

  .btn-avancar,
  .form-produto {
    width: 100%;
  }

  .logo-rodape {
    margin: 0 auto;
    padding-top: 80px;
  }

  .linha-cinza {
    width: 100%;
  }

  .etapa-um {
    width: 50%;
  }

  .etapa-tres {
    width: 100%;
  }

  .card-prod {
    width: 31%;
  }

  .produtos-selecionados .card-prod {
    width: 48%;
  }

  .imagem-produtos {
    gap: 8px;
  }

  .desk {
    display: none;
  }

}
</style>