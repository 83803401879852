<template>
  <div class="bgbeneficio" id="beneficios">
    <img v-if="!usuarioLogado.perfilCompleto || !usuarioLogado.perfilDefinido" src="@/assets/img/mask-bloq.png"
      alt="Imagem de bloqueio" class="bloqueio verdesktop" />

    <img v-if="!usuarioLogado.perfilCompleto || !usuarioLogado.perfilDefinido" src="@/assets/img/maskbloqmob.png"
      alt="Imagem de bloqueio" class="bloqueio vermobile" />

    <section class="container">

      <div class="loading_global" v-if="loading">
        <span class="loader"></span>
      </div>

      <div v-else>

        <div class="conteudo-beneficios d-flex" :class="{ 'saldos': usuarioLogado.colaborador }">
          <div>
            <h2>BENEFÍCIOS</h2>

            <div v-if="beneficios.length == 0">
              <p class="txt-informativo" style="padding-bottom: 40px;">Nenhum benefício disponível!</p>
            </div>

            <div v-else>
              <div v-if="!usuarioLogado.perfilDefinido && usuarioLogado.perfilCompleto">
                <p class="txt-desbloqueio" :class="{ 'txt-colaborador': usuarioLogado.colaborador }">PARA DESBLOQUEAR A
                  SEÇÃO DE BENEFÍCIOS: </p>
                <router-link to="/personalizar-portal" class="btn-trava"
                  :class="{ 'btn-colaborador': usuarioLogado.colaborador }">PERSONALIZAR MEU PORTAL</router-link>
              </div>

              <div v-else-if="!usuarioLogado.perfilCompleto && usuarioLogado.perfilDefinido">
                <p class="txt-desbloqueio" :class="{ 'txt-colaborador': usuarioLogado.colaborador }">PARA DESBLOQUEAR A
                  SEÇÃO DE BENEFÍCIOS </p>
                <router-link to="/editar-perfil" class="btn-trava"
                  :class="{ 'btn-colaborador': usuarioLogado.colaborador }">CONCLUIR CADASTRO</router-link>
              </div>

              <div v-else-if="!usuarioLogado.perfilCompleto || !usuarioLogado.perfilDefinido">
                <p class="txt-desbloqueio" :class="{ 'txt-colaborador': usuarioLogado.colaborador }">PARA DESBLOQUEAR A
                  SEÇÃO DE BENEFÍCIOS:</p>
                <div class="d-flex box-travas" :class="{ 'saldos': usuarioLogado.colaborador }">
                  <router-link to="/personalizar-portal" class="btn-bloqueio">PERSONALIZAR MEU PORTAL</router-link>
                  <router-link to="/editar-perfil" class="btn-bloqueio">CONCLUIR CADASTRO</router-link>
                </div>
              </div>

              <div v-else>
                <p class="txt-informativo">{{ primeiroNome }}, EXPLORE SUAS VANTAGENS EXCLUSIVAS.</p>
              </div>
            </div>

            <Notificacao :infos="notificacao_infos" v-if="notificacao_infos.mensagem" />
          </div>

          <div>
            <div class="selo-saldo d-flex" v-if="!usuarioLogado.colaborador">
              <p class="qtd-ind">{{ indicacoes.saldo }}</p>
              <p class="concluido">SALDO DE<br> INDICAÇÕES</p>
              <img src="@/assets/img/help.svg" alt="Imagem de interrogação" class="interrogacao" @click="explicacao" />
            </div>
            <div class="versaldo" v-if="!usuarioLogado.colaborador">
              <router-link to="extrato" class="btn-saldo">VER SALDO</router-link>
            </div>
          </div>

        </div>

        <div class="cupons d-flex" :class="{ 'estiloMobile': beneficios.length == 1 }">

          <BeneficioUnificado :saldoindicacao="this.indicacoes.saldo" :usuarioLogado="usuarioLogado"
            :texto="nomebeneficiopadrao" :dados="getBeneficiosUnificados" @open-unificado="receberUnificados" />

          <div v-for="(beneficio, index) in beneficiosOrdenadosSemUnificados" :key="index">
            <div class="cupom">
              <img :src="beneficio.urlImagem" alt="Imagem do cupom" />
              <div :class="{
                'bloqueado': beneficio.esgotado || !beneficio.podeResgatar,
                'resgatado': beneficio.jaResgatado && !beneficio.podeResgatar,
                'nao-resgatado': beneficio.podeResgatar && !beneficio.esgotado,
                'utilizado': beneficio.resgates.length > 0 && beneficio.resgates[0].usoCupom && !beneficio.multiploResgate
              }">
                <img v-if="beneficio.tipo == 'Colaboradores' || beneficio.tipo == 'baseGeral'"
                  src="@/assets/img/icone-branco.png" alt="Imagem de favicon" class="icone-cupom" />

                <div class="d-flex ingressos">
                  <p class="txt-cupom">{{ beneficio.nome }}</p>
                  <img v-if="beneficio.evento" src="@/assets/img/tickets.png" alt="Imagem de ticket" class="ticket" />
                </div>

                <div class="box-indicacoes"
                  v-if="(!usuarioLogado.colaborador) && beneficio.valor && beneficio.valor > 0">
                  <div class="d-flex qtd-indicacoes">
                    <h3>{{ beneficio.valor }}</h3>
                    <img src="@/assets/img/icone-branco.png" alt="Imagem de favicon" class="icone-indicacao" />
                  </div>
                  <p class="txt-indicacaoes">INDICAÇÕES</p>
                </div>
              </div>
            </div>

            <div>
              <div v-if="beneficio.jaResgatado && !beneficio.podeResgatar">

                <div class="btn-cupom">
                  <div v-if="beneficio.resgates[0].usoCupom && !beneficio.multiploResgate">
                    <button type="button" class="btn-bloqueado resgatado btn-utilizado">UTILIZADO! </button>
                  </div>
                  <div v-else>
                    <button v-if="beneficio.evento" type="button" class="btn-bloqueado resgatado"
                      @click="openModal(beneficio)">VER DETALHES! </button>
                    <button v-else type="button" class="btn-bloqueado resgatado" @click="openModal(beneficio)">VER
                      CUPOM! </button>

                  </div>

                  <b-progress v-if="!usuarioLogado.colaborador && beneficio.valor && beneficio.valor > 0"
                    :value="progresso(index)" max="100" class="barra-progresso"></b-progress>

                  <div v-if="beneficio.resgates[0].usoCupom && !beneficio.multiploResgate">
                    <div v-for="(cupom, index) in cupons" :key="index">
                      <div>
                        <h3 v-if="(cupom.idBeneficio == beneficio.id) && cupom.dataUsoCupom" class="usoCupom">CUPOM
                          UTILIZADO EM: {{ formatarData(cupom.dataUsoCupom) }}</h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div v-else>
                <div>
                  <div class="btn-cupom" v-if="beneficio.esgotado">
                    <button type="button" class="btn-bloqueado" disabled>ESGOTADO! <img src="@/assets/img/cadeado.png"
                        alt="Icone de copiar" /></button>
                    <b-progress v-if="!usuarioLogado.colaborador && beneficio.valor && beneficio.valor > 0"
                      :value="progresso(index)" max="100" class="barra-progresso"></b-progress>
                  </div>

                  <div class="btn-cupom" v-else-if="!beneficio.podeResgatar">
                    <button type="button" class="btn-bloqueado" disabled>RESGATAR! <img src="@/assets/img/cadeado.png"
                        alt="Icone de copiar" /></button>
                    <b-progress v-if="!usuarioLogado.colaborador && beneficio.valor && beneficio.valor > 0"
                      :value="progresso(index)" max="100" class="barra-progresso"></b-progress>
                  </div>

                  <div class="btn-cupom" v-else>
                    <button type="button" @click="openModal(beneficio)" id="resgatarCupom"
                      :disabled="!usuarioLogado.perfilCompleto || !usuarioLogado.perfilDefinido">RESGATAR!</button>
                    <b-progress v-if="!usuarioLogado.colaborador && beneficio.valor && beneficio.valor > 0"
                      :value="progresso(index)" max="100" class="barra-progresso"></b-progress>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>

        <b-modal id="modal-resgatar" hide-footer>
          <img :src="modalBeneficio.urlImagem" alt="Imagem do cupom" />

          <div class="conteudo-modal" v-if="!modalBeneficio.jaResgatado">
            <p v-if="!modalUnificado" class="nomemodal">{{ modalBeneficio.nome }}</p>

            <div v-if="modalUnificado">

              <p v-if="modalUnificado" class="nomemodal">{{ nomebeneficiopadrao }}</p>

              <span @click="funcao()" style="font-weight: bold;">SELECIONAR DIA:</span>
              <!-- <select style="font-size: 17px;" class="teste" v-model="idSelecionadoUnificar">
                <option :style="index % 2 == 0 ? { backgroundColor: '#EEEEEE' } : {}"
                  v-for="(item, index) in getBeneficiosUnificados" :key="item.id" :value="item.id">
                  {{ formatarNome(item.nome) }}
                </option>
              </select> -->



              <div class="custom-select">
                <div class="selected-option" @click="toggleDropdown">
                  {{ formatarNome(this.nomeSelecionadoUnificar) || 'Selecione'}}
                  <span class="dropdown-icon">▼</span>
                </div>
                <div class="options" v-if="dropdownOpen">
                  <div v-for="(item, index) in getBeneficiosUnificados" :key="item.id" :value="item.id" class="option" @click="selectOption(item.id, item.nome, item.esgotado)"
                  :style="index % 2 == 0 ? { backgroundColor: '#EEEEEE' } : {}"
                  :class="item.esgotado ? 'class1' : 'class2'"
                  
                  >
                    {{ formatarNome(item.nome) }} <span v-if="item.esgotado" style="color: #919191;"> - ESGOTADO</span>
                  </div>
                </div>
              </div>

            </div>

            <div class="textolegal" v-html="modalBeneficio.textoLegal"> </div>


            <div class="checkbox-form">
              <input type="checkbox" id="termo" name="termo" value="termos" v-model="termosAceitos" required />
              <label for="termo">Estou ciente e aceito termos e condições</label><br />
            </div>

            <div v-if="msgTermos" class="error">
              <p>Você deve concordar com os termos antes de resgatar o benefício.</p>
            </div>

            <button v-if="modalBeneficio.tipo == 'Colaboradores' || modalBeneficio.tipo == 'baseGeral'" type="button"
              @click="SiteColabordores">RESGATAR</button>
            <button v-else-if="modalBeneficio.tipo == tipoParaUnificar" type="button"
              @click="resgatarBeneficio(idSelecionadoUnificar)">RESGATAR</button>
            <button v-else type="button" @click="resgatarBeneficio(modalBeneficio.id)">RESGATAR</button>
          </div>

          <div class="conteudo-modal"
            :class="{ 'estiloPersonalizacao': modalBeneficio.tipo == 'Missao_Personalizacao' }" v-else>
            <p v-if="modalBeneficio.tipo != 'Missao_Personalizacao'" class="nomemodal titulo-beneficio">{{
              modalBeneficio.nome }}</p>
            <p v-else class="nomemodal titulo-beneficio">{{ modalBeneficio.nome }}</p>

            <div v-if="modalBeneficio.tipo == 'Missao_Personalizacao'" class="txt-parabens">
              <h2>PARABÉNS!</h2>
              <h3>VOCÊ É O LEGIONÁRIO NÚMERO</h3>

              <div class="selo-numero">
                <img src="@/assets/img/selo-copo.png" alt="Imagem do selo" class="selo-copo" />
                <div v-for="(cupom, index) in cupons" :key="index">
                  <h3 v-if="cupom.idBeneficio == modalBeneficio.id" class="posicao-beneficio">{{ cupom.numeroCupom
                    }}/1913</h3>
                </div>

              </div>
            </div>

            <div class="dataCupom">
              <p><span> Resgatado em: </span>{{ formatarData(modalBeneficio.resgates[0].dataResgate) }}</p>
            </div>

            <div v-if="modalBeneficio.tipo === tipoParaUnificar"></div>


            <div class="txt-cupons" v-else-if="!modalBeneficio.evento">
              <div class="btn-codigo" :class="{ 'removerCupom': cupom.idBeneficio != modalBeneficio.id }"
                v-for="(cupom, index) in cupons" :key="index">
                <div class="d-flex box-cupom-resgatado"
                  v-if="(cupom.idBeneficio == modalBeneficio.id) && !cupom.usoCupom">
                  <input class="codigo-cupom" v-model="cupom.cupom" />
                  <img src="@/assets/img/copiar.svg" alt="Icone de copiar" class="icone-copiar"
                    @click="copiarTexto($event)" />
                </div>

              </div>

            </div>
            <div v-if="modalBeneficio.tipo == 'Missao_Personalizacao'">
              <button type="button" @click="UsarCupomCopo()" class="usarCupom">USAR O CUPOM</button>
            </div>

            <div v-else-if="modalBeneficio.tipo === tipoParaUnificar"></div>

            <div v-else>
              <div v-if="modalBeneficio.evento"> </div>
              <div v-else>
                <button type="button" @click="UsarCupom()" class="usarCupom">USAR O CUPOM</button>
              </div>
            </div>

            <p v-if="eventoAtual" class="txt-fortal">
              <strong>INGRESSOS RESGATADOS!</strong><br>
              Até o dia {{ eventoAtual.data }}, você receberá seu par de ingressos e as orientações de retirada via
              WhatsApp pelo número
              cadastrado no Portal Legionários. Para dúvidas, entre em contato com o nosso SAC clicando
              <a href="https://www.stanley1913.com.br/contato" target="_blank">aqui.</a>
            </p>

            <p v-else-if="modalBeneficio.tipo === tipoParaUnificar" class="txt-fortal">
              <strong>INGRESSOS RESGATADOS!</strong><br>
              Até o dia 16/01, você receberá seu par de ingressos e as orientações de retirada via
              WhatsApp pelo número
              cadastrado no Portal Legionários. Para dúvidas, entre em contato com o nosso SAC clicando
              <a href="https://www.stanley1913.com.br/contato" target="_blank">aqui.</a>
            </p>

            <!--<div class="validade"><p> Cupom válido até {{ formatarData(modalBeneficio.dataFim) }}, e não é acumulativo. </p></div>  -->

            <div class="div-txt">
              <div class="validade" v-html="modalBeneficio.textoLegal"></div>
            </div>

            <div v-if="modalBeneficio.multiploResgate && modalBeneficio.podeResgatar">
              <div class="checkbox-form">
                <input type="checkbox" id="termo" name="termo" value="termos" v-model="termosAceitosMultiplo"
                  required />
                <label for="termo">Estou ciente e aceito termos e condições</label><br />
              </div>

              <div v-if="msgTermosMultiplo" class="error">
                <p>Você deve concordar com os termos antes de resgatar o benefício.</p>
              </div>

              <button type="button" @click="resgatarBeneficioMultiplo(modalBeneficio.id)"
                :disabled="!usuarioLogado.perfilCompleto || !usuarioLogado.perfilDefinido">RESGATAR!</button>
            </div>

          </div>

        </b-modal>


        <b-modal id="modal-saldo" hide-footer title="SALDO DE INDICAÇÕES">
          <div>
            <p>O sistema de indicações no Portal Legionários permite que você acumule saldo de indicações, que pode ser
              trocado por diversos benefícios.</p>

            <h4>Como funciona:</h4>

            <p>
              <strong>Acumular Saldo: </strong> Cada vez que você faz uma indicação bem-sucedida, seu saldo de
              indicações aumenta.

            </p>

            <p>
              <strong>Troca por Benefícios:</strong> Esse saldo pode ser utilizado para resgatar benefícios exclusivos
              disponíveis no portal.
            </p>

            <p>
              <strong>Subtração do Saldo: </strong>Ao usar suas indicações para resgatar um benefício, o valor
              correspondente será subtraído
              do seu saldo de indicações.
            </p>

            <p>
              <strong>Saldo Restante: </strong>O saldo restante poderá ser utilizado para resgatar outros benefícios.
            </p>
          </div>
        </b-modal>
      </div>
    </section>

  </div>
</template>

<script>
import { api } from "@/services.js";
import { format } from 'date-fns';
import { EventBus } from '@/EventBus.js';
import BeneficioUnificado from "@/components/BeneficioUnificado.vue";
export default {
  name: "Beneficios",
  components: {
    BeneficioUnificado
  },
  data() {
    return {
      indicacoes: [],
      msgTermosMultiplo: false,
      loading: false,
      termosAceitos: false,
      termosAceitosMultiplo: false,
      msgTermos: false,
      usuarioLogado: [],
      beneficios: [],
      cupons: [],
      modalBeneficio: {},
      dropdownOpen: false,
      selectedOption: null,
      options: ["17 DE JANEIRO", "18 DE JANEIRO", "19 DE JANEIRO"], 
      beneficioatribuido: '',
      eventos: [
        { tipo: 'EventoRegional', data: '17/07' },
        { tipo: 'EventoJorgeMateus', data: '18/10' },
        { tipo: 'SorrisoMarotoAsAntigas', data: '14/11' },
        { tipo: 'SorrisoMarotoAsAntigasPE', data: '29/11' },
        { tipo: 'MenoseMaisRS', data: '06/12' },
        { tipo: 'SorrisoMarotoAsAntigasSP', data: '06/12' },
        { tipo: 'MenoseMaisPR', data: '14/11' },
        { tipo: 'SorrisoMaroto', data: '18/10' },
        { tipo: 'MenoseMais', data: '25/10' },
        { tipo: 'EventoPiata', data: '27/07' },
        { tipo: 'JorgeMateus', data: '16/08' },
        { tipo: 'GusttavoLima', data: '27/08' },
      ],
      tipoParaUnificar: 'ParIngressoSpantaRJ',
      nomebeneficiopadrao: 'PAR DE INGRESSOS UNIVERSO SPANTA - RJ',
      modalUnificado: false,
      idSelecionadoUnificar: null,
      nomeSelecionadoUnificar: '',
      notificacao_infos: {
        variant: "info",
        mensagem: "",
        count_timer: 0,

      },
    };
  },
  created() {
    EventBus.$on('cupomGerado', this.mostrarCupom);
    EventBus.$on('beneficioSM', this.mostrarBeneficio);

  },
  methods: {

    toggleDropdown() {
      this.dropdownOpen = !this.dropdownOpen;
    },
    selectOption(option, optiontoo, esgotado) {

      if(esgotado){
        return
      }

      this.idSelecionadoUnificar = option;
      this.nomeSelecionadoUnificar = optiontoo
      this.dropdownOpen = false;
    },


    formatarNome(nome) {
      return nome.replace('PAR DE INGRESSOS DIA ', '').trim();
    },

    receberUnificados(dado) {
      this.modalBeneficio = dado
      setTimeout(() => {
        this.$bvModal.show('modal-resgatar');
      }, 200);

      this.modalUnificado = true
    },

    progresso(index) {
      return (this.indicacoes.saldo / this.beneficios[index].valor) * 100;
    },

    buscarBeneficiosPorId(id) {
      this.loading = true;
      api
        .get(`/beneficio/${id}`)
        .then((resp) => {
          const beneficiosPorId = resp.data;
          this.openModal(beneficiosPorId)
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    SiteColabordores() {
      if (!this.termosAceitos) {
        this.msgTermos = true;
      } else {
        window.open("http://www.stanley1913.com.br/parceiros/lojinha", "_blank")
      }
    },
    UsarCupom() {
      window.open("https://www.stanley1913.com.br/", "_blank")
    },

    UsarCupomCopo() {
      window.open("https://stanley1913.com.br/parceiros/legionarios", "_blank")
    },

    encontrarBeneficioEspecifico() {
      return this.beneficios.find(
        beneficio => beneficio.tipo === "Missao_Comece_Colecao"
      );
    },

    encontrarBeneficio() {
      
      //quando for beneficiounificado
        // this.receberUnificados(this.getBeneficiosUnificados[0])


      return this.beneficios.find(
      beneficio => beneficio.nome === this.beneficioatribuido || ''
      );
    },

    mostrarBeneficio() {
      if (this.usuarioLogado.perfilCompleto && this.usuarioLogado.perfilDefinido) {
        setTimeout(() => {
          const beneficioEspecifico = this.encontrarBeneficio();
          if (beneficioEspecifico) {
            this.openModal(beneficioEspecifico);
          } else {
            console.error("Benefício não encontrado.");
          }
        }, 1000);
      }
    },

    mostrarCupom() {
      if (this.usuarioLogado.perfilCompleto && this.usuarioLogado.perfilDefinido) {
        setTimeout(() => {
          const beneficioEspecifico = this.encontrarBeneficioEspecifico();
          if (beneficioEspecifico) {
            this.openModal(beneficioEspecifico);
          } else {
            console.error("Benefício não encontrado.");
          }
        }, 1000);
      }

    },

    voltar() {
      this.$router.push("/home")
    },

    openModalColaborador(beneficio) {
      this.modalBeneficio = beneficio;
      this.modalUnificado = false
      this.$bvModal.show('modal-resgatar');
    },

    explicacao() {
      this.$bvModal.show('modal-saldo');
    },

    openModal(beneficio) {
      if ((!beneficio.esgotado && beneficio.podeResgatar) || beneficio.jaResgatado) {
        this.modalBeneficio = beneficio;
        this.modalUnificado = false
        setTimeout(() => {
          this.$bvModal.show('modal-resgatar');
        }, 200);
      }
    },

    buscarUsuarios() {
      this.loading = true;
      api
        .get('/usuario/logged')
        .then((resp) => {
          this.usuarioLogado = resp.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    copiarTexto(event) {
      const inputElement = event.target.previousElementSibling;
      inputElement.select();
      document.execCommand('copy');
    },

    buscarBeneficios() {
      this.loading = true;
      api
        .get('/beneficio')
        .then((resp) => {
          this.beneficios = resp.data;
          // this.getoptions();
          this.buscarIndicacoes();
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    buscarIndicacoes() {
      this.loading = true;
      api
        .get('/indicacao')
        .then((resp) => {
          this.indicacoes = resp.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getoptions() {
      if (this.getBeneficiosUnificados.length > 0) {
        if(!this.getBeneficiosUnificados[0].esgotado){
          this.idSelecionadoUnificar = this.getBeneficiosUnificados[0].id;
          this.nomeSelecionadoUnificar = this.getBeneficiosUnificados[0].nome;
        } else if(!this.getBeneficiosUnificados[1].esgotado){
          this.idSelecionadoUnificar = this.getBeneficiosUnificados[1].id;
          this.nomeSelecionadoUnificar = this.getBeneficiosUnificados[1].nome;
        } else if(!this.getBeneficiosUnificados[2].esgotado){
          this.idSelecionadoUnificar = this.getBeneficiosUnificados[2].id;
          this.nomeSelecionadoUnificar = this.getBeneficiosUnificados[2].nome;
        } else{
          this.idSelecionadoUnificar = this.getBeneficiosUnificados[3].id;
          this.nomeSelecionadoUnificar = this.getBeneficiosUnificados[3].nome;
        }
      }
    },

    buscarCupons() {
      this.loading = true;
      api
        .get('/beneficio/cupom')
        .then((resp) => {
          this.cupons = resp.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    resgatarBeneficio(idBeneficio) {
      if (!this.termosAceitos) {
        this.msgTermos = true;
      } else {
        this.loading = true;
        const data = {
          idBeneficio: idBeneficio,
        };

        api
          .post("/beneficio/resgate", data)
          .then(() => {
            this.buscarCupons();
            this.buscarBeneficios();

            this.buscarBeneficiosPorId(idBeneficio);
          })
          .catch((error) => {
            console.error(error);

            this.notificacao_infos = {
              variant: "error",
              mensagem: error.response
                ? error.response.data.errors[0]?.message
                : "Algo deu errado na requisição",
              count_timer: 5,
            };

          })
          .finally(() => {
            this.loading = false;
          });
      }
    },

    funcao(){
      console.log(this.getBeneficiosUnificados)
    },

    resgatarBeneficioMultiplo(idBeneficio) {
      this.modalUnificado = false
      if (!this.termosAceitosMultiplo) {
        this.msgTermosMultiplo = true;
      } else {
        this.loading = true;
        const data = {
          idBeneficio: idBeneficio,
        };

        api
          .post("/beneficio/resgate", data)
          .then(() => {
            this.buscarCupons();
            this.buscarBeneficios();
          })
          .catch((error) => {
            console.error(error);

            this.notificacao_infos = {
              variant: "error",
              mensagem: error.response
                ? error.response.data.errors[0]?.message
                : "Algo deu errado na requisição",
              count_timer: 5,
            };

          })
          .finally(() => {
            this.loading = false;

          });
      }
    },

    formatarData(dataFim) {
      if (dataFim) {
        return format(new Date(dataFim), 'dd/MM/yyyy');
      }
      return null;
    },
  },
  beforeDestroy() {
    EventBus.$off('cupomGerado', this.mostrarCupom);
  },
  computed: {

    getBeneficiosUnificados() {
      return this.beneficiosOrdenados.filter(item => item.tipo === this.tipoParaUnificar).reverse()
      

    },

    beneficiosOrdenadosSemUnificados() {
      return this.beneficiosOrdenados.filter(item => item.tipo !== this.tipoParaUnificar)
    },

    beneficiosOrdenados() {
      return this.beneficios.slice().sort((a, b) => {
        if (a.podeResgatar !== b.podeResgatar) {
          return a.podeResgatar ? -1 : 1;
        }
        if (a.evento !== b.evento) {
          return a.evento ? -1 : 1;
        }
        return 0;
      });
    },
    eventoAtual() {
      return this.eventos.find(evento => evento.tipo === this.modalBeneficio.tipo);
    },
    primeiroNome() {
      if (this.usuarioLogado.nome) {
        const nomeCompleto = this.usuarioLogado.nome;
        const primeiroNome = nomeCompleto.split(' ')[0];
        return primeiroNome;
      } else {
        return '';
      }
    },

  },
  mounted() {
    this.buscarCupons();
    this.buscarUsuarios();
    this.buscarBeneficios();
    this.buscarIndicacoes();
    this.beneficioatribuido = localStorage.getItem('beneficioatribuido') || ''

  },

};
</script>

<style scoped>
.txt-colaborador {
  text-align: center !important;
}

.btn-colaborador {
  margin: auto !important;
}

.versaldo {
  display: grid;
  justify-content: end;
}

.btn-saldo {
  font-family: "klavika medium";
  background-color: #FF681E;
  color: white;
  margin-top: 20px;
  padding: 10px 50px;
  text-decoration: none;
  font-size: 18px;
  border: none;
  text-align: center;
}

.btn-saldo:hover {
  background-color: #ec5d16;
}

#beneficios {
  position: relative;
}

.bloqueio {
  width: 100%;
  position: absolute;
}

.box-travas {
  justify-content: start;
  gap: 50px;
}

.qtd-indicacoes {
  gap: 5px;
  align-items: center;
  justify-content: end;
}

.icone-indicacao {
  width: 50px;
}

.qtd-indicacoes h3 {
  color: white;
  font-family: "KNOCKOUT-HTF49-LITEWEIGHT";
  font-size: 50px;
  margin-bottom: 0px;
  margin-top: 5px;
}

.interrogacao {
  margin-top: -45px;
  cursor: pointer;
  width: 30px;
}

.teste {
  overflow: hidden !important;
}

.txt-indicacaoes {
  text-align: right;
  margin-bottom: 0px !important;
  color: white;
  margin-top: -10px;
  font-size: 25px;
  font-family: "KNOCKOUT-HTF49-LITEWEIGHT";
}

.btn-trava {
  transition: background-color 0.3s ease;
  background-color: #00ABC8;
  color: white;
  font-size: 18px;
  border: none;
  text-align: center;
  display: block;
  width: 300px;
  text-decoration: none;
  padding: 12px 0px;
}

.btn-bloqueio {
  transition: background-color 0.3s ease;
  background-color: #00ABC8;
  color: white;
  font-size: 18px;
  border: none;
  text-align: center;
  display: block;
  width: 300px;
  text-decoration: none;
  padding: 12px 0px;
}

.btn-trava:hover,
.btn-bloqueio:hover {
  background-color: #00b6d5;
}

.teste {
  margin-bottom: 15px;
}

.estiloPersonalizacao .nomemodal {
  text-align: center !important;
  border-bottom: 1px solid black;
}

.posicao-beneficio {
  font-family: "KNOCKOUT-HTF49-LITEWEIGHT";
  color: #232527;
  font-size: 20px;
  text-align: center;
  top: 30px !important;
  position: absolute;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  left: 0px;
}

.selo-numero {
  position: relative;
}

.selo-copo {
  width: 150px;
  margin: auto;
  padding-bottom: 20px;
}
.custom-select {
  position: relative;
  width: 90%;
    font-family: Arial, sans-serif;
    margin-bottom: 10px;
}

.selected-option {
  border: 1px solid #000;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  font-weight: bold;
}

.dropdown-icon {
  margin-left: 10px;
}

.options {
  border: 1px solid #000;
  border-top: none;
  position: absolute;
  width: 100%;
  background-color: #fff;
  z-index: 10;
}

.option {
  padding: 10px;
  cursor: pointer;
  font-weight: bold;
}

.option:hover {
  background-color: #f0f0f0;
}

.txt-parabens h2 {
  font-family: "KNOCKOUT-HTF70";
  color: #232527;
  font-size: 35px;
  text-align: center;
}

.txt-parabens h3 {
  font-family: "KNOCKOUT-HTF49-LITEWEIGHT";
  color: #232527;
  font-size: 23px;
  text-align: center;
}

.usarCupom {
  margin-top: 10px !important;
  margin-bottom: 20px;
  background-color: #232527 !important;
  font-size: 25px !important;
}

.bgbeneficio {
  padding-bottom: 40px;
  background-color: #565959;
}

.notificacao.error {
  width: 500px;
  margin-bottom: 30px;
  background: rgb(255 212 214);
}

.icone-copiar {
  cursor: pointer;
  filter: invert(1);
}

.codigo-cupom {
  padding: 0px;
  border: none;
  font-family: "klavika medium";
  color: #000000;
  font-size: 25px;
}

.usoCupom {
  font-family: "klavika medium";
  margin-top: 10px;
  text-align: center;
  letter-spacing: 0.5px;
  color: white;
  font-size: 15px;
}



.dataCupom {
  text-align: center;
  border-top: 2px solid black;
  border-bottom: 2px solid black;
  margin-bottom: 0px;
}

.dataCupom span {
  font-family: "klavika medium";
  color: #87b21b;
  font-size: 15px;
  letter-spacing: 0.5px;
}

.dataCupom p {
  padding: 5px 0px;
  margin-bottom: 0px;
}

.error p {
  color: #bf0000;
  font-size: 13px;
  border-radius: 5px;
  text-align: center;
  margin-bottom: 0px;
  line-height: 14px;
  margin-top: 10px;
  padding: 10px;
  background: rgb(255 212 214);
}

.box-cupom-resgatado {
  padding: 0px 5px;
  justify-content: center;
}

.btn-cupom img {
  width: 24px;
}

.btn-codigo img:hover {
  filter: contrast(0.5);
}

.btn-cupom button,
.conteudo-modal button {
  align-items: center;
  display: flex;
  justify-content: center;
  gap: 10px;
  font-family: "KNOCKOUT-HTF49-LITEWEIGHT";
  margin-top: 20px;
  background-color: #FF681E;
  font-size: 32px;
  letter-spacing: 1px;
  border: none;
  color: white;
  height: 55px;
  width: 100%;
}

.conteudo-modal {
  margin-bottom: 20px;
}

.btn-bloqueado {
  border: 1px solid #848484 !important;
  color: #848484 !important;
  background-color: #52575A !important;
}

.removerCupom {
  display: none !important;
}

.txt-cupons {
  margin-bottom: 5px;
}

.validade {
  font-family: "klavika medium";
  color: #FFFFFF;
  font-size: 15px;
  text-transform: uppercase;
  text-align: center;
}

.btn-resgatado {
  background-color: #968A83;
  margin-top: 20px;
  justify-content: center;
  gap: 20px;
}

.btn-codigo img {
  cursor: pointer;
}

.codigo {
  cursor: default;
  letter-spacing: 1px;
  font-family: "KNOCKOUT-HTF49-LITEWEIGHT";
  font-size: 36px;
  text-transform: uppercase;
  padding: 0px;
  background-color: transparent;
  border: none;
  width: 110px;
  text-align: center;
  color: white;
}

.btn-codigo {
  margin-top: 5px;
  justify-content: center;
  gap: 20px;
}

.btn-codigo p,
.btn-resgatado p {
  font-family: "KNOCKOUT-HTF49-LITEWEIGHT";
  color: white;
  font-size: 36px;
  margin-bottom: 0px;
}

.icone-cupom {
  width: 17%;
}

.bloqueado {
  background-color: #52575A;
  line-height: 32px;
  padding: 15px;
  min-height: 250px;
  border: 1px solid #848484 !important;
  align-items: start;
}

.nao-resgatado {
  background-color: #FF681E !important;
  line-height: 32px;
  padding: 15px;
  min-height: 250px;
  align-items: start;
}

.resgatado {
  color: white !important;
  background-color: #407BBF !important;
}

.class1{
  color: #919191 !important;
  text-decoration: line-through
}

.utilizado {
  background-color: #6F8473 !important;
}

.btn-utilizado {
  background-color: #6F8473 !important;
  cursor: default !important;
}

.cupons {
  justify-content: start;
  gap: 20px;
  overflow: auto;
  padding-bottom: 50px;
}

.cupom {
  width: 308px;
  background-color: #52575A85;
  border: 3px solid #FFFFFF;
  padding: 10px;
}

.txt-cupom {
  color: white;
  text-transform: uppercase;
  font-size: 28px;
  line-height: 31px;
  font-family: "KNOCKOUT-HTF49-LITEWEIGHT";
  min-height: 109px;
}

.txt-pontos {
  font-family: "Knockout";
  color: #FFFFFF;
  font-size: 60px;
}

.desconto {
  font-family: "Knockout";
  color: #FFFFFF;
  font-size: 40px;
}

.conteudo-beneficios {
  position: relative;
  z-index: 999;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 50px;
  padding-top: 50px;
}

.saldos {
  text-align: center;
  justify-content: center;
  align-items: center;
}

.conteudo-beneficios h2 {
  letter-spacing: 1.54px;
  font-family: "Knockout";
  color: #FF681E;
  font-size: 108px;
  line-height: 100px;
}

.conteudo-beneficios .txt-informativo {
  margin: 0 auto;
  width: 700px;
  color: #FFFFFF;
  letter-spacing: 1.8px;
  font-size: 19px;
  line-height: 23px;
  text-transform: uppercase;
}

.txt-desbloqueio {
  margin: 0 auto;
  width: 700px;
  color: #FFFFFF;
  text-align: left;
  letter-spacing: 1px;
  font-size: 19px;
  line-height: 20px;
  margin-bottom: 20px;
  font-family: "klavika bold";
}

.txt-desbloqueio a {
  color: #FFFFFF;
}

.link-pontos {
  font-family: "klavika medium";
  color: white !important;
}

.pontos {
  height: 95px;
  margin-top: -15px;
  justify-content: center;
}

.pontos p {
  color: #FF681E;
  font-family: "KNOCKOUT-HTF70";
  font-size: 80px;
}

.pontos span {
  margin-top: 10px;
  color: #FF681E;
  font-family: "klavika medium";
  font-size: 40px;
}

.anterior {
  background-color: transparent;
  border: none;
}

.seta {
  width: 14px;
  margin-right: 15px;
  cursor: pointer;
}

.voltar {
  padding: 0px !important;
  display: flex;
  margin: 15px 0px 30px 0px;
}

.voltar span {
  color: #fff;
  font-weight: 200;
}

.voltar p {
  margin-bottom: 0px;
  line-height: 17px;
  color: #fff;
  font-size: 14px;
  font-family: "klavika-web", sans-serif;
  font-weight: 700;
}

.logo-rodape {
  margin: 0 auto;
  padding-top: 80px;
}

.logo-mobile {
  width: 200px;
  top: 35px;
  left: 20px;
}

.menu-mobile {
  text-align: right;
  float: right;
  position: relative;
  right: 40px;
}

.nav-item a {
  color: #FFFFFF;
  font-size: 22px;
  font-family: "klavika light";
  font-weight: 300;
}

.nav-item a:hover {
  color: #D4D1C4;
}

.active a:after {
  content: '';
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #FF681E;
  margin-left: 12px;
  position: relative;
  top: -3px;
}

.active a {
  color: #D4D1C4 !important;
}

.mob {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0px !important;
  padding-top: 20px;
}

.copyright {
  margin-top: 20px;
}

.selo {
  margin: 0 auto;
  border: 2px dashed #FF681E;
  padding: 5px 20px;
  align-items: center;
  width: 230px;
  height: 125px;
  gap: 8px;
  text-align: center;
  margin-bottom: 50px;
}

.selo-saldo {
  justify-content: space-between;
  border: 2px dashed #D4D1C4;
  padding: 5px 10px;
  align-items: center;
  width: 100%;
  height: 95px;
  gap: 20px;
}

.qtd-ind {
  font-family: "KNOCKOUT-HTF70" !important;
  color: #FF681E !important;
  font-size: 70px !important;
  margin-bottom: 0px !important;
  margin-top: 6px !important;
}

.concluido {
  margin-bottom: 0px !important;
  color: #FBFBFB !important;
  font-family: "Knockout";
  font-size: 34px !important;
  line-height: 37px;
  letter-spacing: 2px;
}

.barra-progresso {
  margin-top: 10px;
}

.estiloMobile {
  justify-content: center !important;
}

.ingressos {
  align-items: flex-start;
}

.txt-fortal {
  margin-top: 20px;
}

.ticket {
  width: 35px;
}

.div-txt {
  height: 165px;
  overflow: auto;
}

@media (min-width: 991px) {
  .vermobile {
    display: none;
  }
}

@media (max-width: 990px) {

  .versaldo {
    justify-content: start;
  }

  .btn-trava {
    width: 100%;
  }

  .btn-bloqueio {
    width: 100%;
    margin-bottom: 20px;
  }

  .selo-saldo {
    margin-top: 20px;
  }

  .conteudo-beneficios {
    margin-bottom: 30px !important;
    display: block !important;
  }

  .box-travas {
    display: block !important;
  }

  .txt-desbloqueio {
    width: 100%;
    text-align: left;
  }

  .verdesktop {
    display: none;
  }

  .cupons {
    justify-content: start;
  }

  .cupom {
    width: 300px;
  }

  .btn-cupom button {
    margin-top: 0px;
  }

  .conteudo-beneficios h2 {
    text-align: left;
    font-size: 70px;
    line-height: 50px
  }

  .conteudo-beneficios .txt-informativo {
    text-align: left;
    width: 100%;
  }

  .cupons {
    overflow: auto;
    padding-bottom: 20px;
  }

  .cupom {
    margin-bottom: 20px;
  }

  .selo {
    margin-bottom: 70px;
  }

  .logo-mobile {
    width: 150px;
  }

  .copyright {
    margin-bottom: 30px;
  }

  /*.bgbeneficio {
background-image: url('@/assets/img/beneficios-mob.png');
}*/

}
</style>