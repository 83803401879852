<template>
    <div >
        <div v-if="load" style="min-height: 90vh; display: flex; align-items: center; justify-content: center;">
            <div class="loader"></div>
        </div>
        <section v-else style="padding: 5% 15% 0 15%;" class="iconnns">
            <div>
                <img src="../assets/img/logo_legionarios_preto.svg" alt="">
                <p style="color: #FF681E; font-weight: bold;">PESQUISA DE SATISFAÇÃO</p>
            </div>
            <div>
                <p style="margin-bottom: -5px;">Etapa {{ fase || 5 }} de 5</p>
                <p style="font-weight: bold;">Sua experiência</p>
            </div>

            <div style="background-color: #00000033; height: 3px; position: relative;">
                <div style="background-color: #00ABC8; height: 6px; position: absolute; top: -1.5px; border-radius: 5px; left: -1px"
                    :style="{ width: lado + '%' }">
                </div>
            </div>

            

            <div v-if="!pesquisaFinalizada" style="margin-top: 30px;">
                <p class="moboption" style="font-size: 40px; color: #4A4A4A; font-weight: bold">{{ fase == 1 ? 'NAVEGAÇÃO E USABILIDADE' : fase == 2 ? 'Benefícios e Recompensas' : fase == 3 ? 'Experiência com a Marca' : fase == 4 ? 'Comunicação e Conteúdo' : fase == 4 ? 'Comunicação e Conteúdo' : 'Impacto Geral e Satisfação'}}
                </p>



                <div v-for="(pergunta) in perguntas" :key="pergunta.idPesquisaPergunta" style="margin-bottom: 20px">
                    <div>
                        <div>
                            <label style="color: #FF681E; margin-bottom: 5px">
                                <span style="font-weight: bold; font-size: 20px; color: #FF681E">{{ pergunta.ordem}}</span>*. 
                                <span style="color: black; font-size: 20px">{{ pergunta.descricao }}</span>
                            </label>
                        </div>

                        <label v-if="pergunta.respostas.length > 1" style="color:#757575; margin-left: 20px;">Selecione uma alternativa: </label>


                        <div style="display: flex; justify-content: start; width: 80%; gap: 30px" class="mobilete">
                            
                            <div v-for="item in pergunta.respostas" :key="item.idPesquisaPerguntaResposta"
                                class="aboutwidth">




                                        <!-- Resposta 3 -->


                                <div v-if="item.ordem === 2 && pergunta.ordem === 3">
                                    <label :for="'opcao-' + item.idPesquisaPerguntaResposta"
                                        :class="{ 'custom-label': true, 'custom-label-selected': respostasSelecionadas[pergunta.idPesquisaPergunta] === item.idPesquisaPerguntaResposta }">
                                        <input style="display: none;" type="radio"
                                            :id="'opcao-' + item.idPesquisaPerguntaResposta"
                                            :name="'opcao-' + pergunta.idPesquisaPergunta"
                                            :value="item.idPesquisaPerguntaResposta"
                                            @change="selectResposta(pergunta, item)" />
                                            {{item.descricao}}
                                    </label>                                    
                                </div>

                                <div v-else-if="item.ordem === 1 && pergunta.ordem === 3">
                                    <label :for="'opcao-' + item.idPesquisaPerguntaResposta"
                                        :class="{ 'custom-label': true, 'custom-label-selected': respostasSelecionadas[pergunta.idPesquisaPergunta] === item.idPesquisaPerguntaResposta }">
                                        <input style="display: none;" type="radio"
                                            :id="'opcao-' + item.idPesquisaPerguntaResposta"
                                            :name="'opcao-' + pergunta.idPesquisaPergunta"
                                            :value="item.idPesquisaPerguntaResposta"
                                            @change="selectResposta(pergunta, item)" />
                                            {{item.descricao}}
                                    </label>

                                        <p v-if="mensagensErro[pergunta.idPesquisaPergunta]" style="color: red; font-size: 12px; width: 300px;">
                                            {{ mensagensErro[pergunta.idPesquisaPergunta] }}
                                        </p>  

                                    <textarea class="" v-if="Object.values(respostasSelecionadas).includes(item.idPesquisaPerguntaResposta +1)"
                                     v-model="respostasComplementos[pergunta.idPesquisaPergunta]"
                                    style="height: 100px; resize: none; font-weight: normal; width: 800px;" placeholder="Digite sobre">
                                    </textarea>
                                    
                                </div>

                                <!-- Resposta 4 -->
                                <div v-else-if="item.ordem === 1 && pergunta.ordem === 4">
                                    <label :for="'opcao-' + item.idPesquisaPerguntaResposta"
                                        :class="{ 'custom-label': true, 'custom-label-selected': respostasSelecionadas[pergunta.idPesquisaPergunta] === item.idPesquisaPerguntaResposta }">
                                        <input style="display: none;" type="radio"
                                            :id="'opcao-' + item.idPesquisaPerguntaResposta"
                                            :name="'opcao-' + pergunta.idPesquisaPergunta"
                                            :value="item.idPesquisaPerguntaResposta"
                                            @change="selectResposta(pergunta, item)" />
                                            {{item.descricao}}
                                        </label>
                                        <p v-if="mensagensErro[pergunta.idPesquisaPergunta]" style="color: red; font-size: 12px; width: 300px;">
                                            {{ mensagensErro[pergunta.idPesquisaPergunta] }}
                                        </p>                                       
                                    <textarea class="" v-if="Object.values(respostasSelecionadas).includes(item.idPesquisaPerguntaResposta)"
                                     v-model="respostasComplementos[pergunta.idPesquisaPergunta]"
                                    style="height: 100px; resize: none; font-weight: normal; width: 800px; " placeholder="Qual?">
                                    </textarea>
                                </div>

                                <!-- Resposta 6 -->
                                <div v-else-if="item.ordem === 1 && pergunta.ordem === 6">
                                    <label :for="'opcao-' + item.idPesquisaPerguntaResposta"
                                        :class="{ 'custom-label': true, 'custom-label-selected': respostasSelecionadas[pergunta.idPesquisaPergunta] === item.idPesquisaPerguntaResposta }">
                                        <input style="display: none;" type="radio"
                                            :id="'opcao-' + item.idPesquisaPerguntaResposta"
                                            :name="'opcao-' + pergunta.idPesquisaPergunta"
                                            :value="item.idPesquisaPerguntaResposta"
                                            @change="selectResposta(pergunta, item)" />
                                            {{item.descricao}}
                                        </label>
                                        <p v-if="mensagensErro[pergunta.idPesquisaPergunta]" style="color: red; font-size: 12px; width: 300px;">
                                            {{ mensagensErro[pergunta.idPesquisaPergunta] }}
                                        </p>                                       
                                    <textarea class="" v-if="Object.values(respostasSelecionadas).includes(item.idPesquisaPerguntaResposta)"
                                     v-model="respostasComplementos[pergunta.idPesquisaPergunta]"
                                    style="height: 100px; resize: none; font-weight: normal; width: 800px; " placeholder="Qual?">
                                    </textarea>
                                </div>

                                <div v-else-if="item.ordem === 2 && pergunta.ordem === 6">
                                    <label :for="'opcao-' + item.idPesquisaPerguntaResposta"
                                        :class="{ 'custom-label': true, 'custom-label-selected': respostasSelecionadas[pergunta.idPesquisaPergunta] === item.idPesquisaPerguntaResposta }">
                                        <input style="display: none;" type="radio"
                                            :id="'opcao-' + item.idPesquisaPerguntaResposta"
                                            :name="'opcao-' + pergunta.idPesquisaPergunta"
                                            :value="item.idPesquisaPerguntaResposta"
                                            @change="selectResposta(pergunta, item)" />
                                            {{item.descricao}}
                                        </label>                                     
                                </div>

                                <!-- Resposta 8 -->
                                <div v-else-if="item.ordem === 1 && pergunta.ordem === 8">
                                    <label :for="'opcao-' + item.idPesquisaPerguntaResposta"
                                        :class="{ 'custom-label': true, 'custom-label-selected': respostasSelecionadas[pergunta.idPesquisaPergunta] === item.idPesquisaPerguntaResposta }">
                                        <input style="display: none;" type="radio"
                                            :id="'opcao-' + item.idPesquisaPerguntaResposta"
                                            :name="'opcao-' + pergunta.idPesquisaPergunta"
                                            :value="item.idPesquisaPerguntaResposta"
                                            @change="selectResposta(pergunta, item)" />
                                            Sim
                                        </label>
                                        <p v-if="mensagensErro[pergunta.idPesquisaPergunta]" style="color: red; font-size: 12px; width: 300px;">
                                            {{ mensagensErro[pergunta.idPesquisaPergunta] }}
                                        </p>                                       
                                    <textarea class="" v-if="Object.values(respostasSelecionadas).includes(item.idPesquisaPerguntaResposta)"
                                     v-model="respostasComplementos[pergunta.idPesquisaPergunta]"
                                    style="height: 100px; resize: none; font-weight: normal; width: 800px; " placeholder="Como foi a experiência?">
                                    </textarea>

                                    <textarea class="" v-if="Object.values(respostasSelecionadas).includes(item.idPesquisaPerguntaResposta + 1)"
                                     v-model="respostasComplementos[pergunta.idPesquisaPergunta]"
                                    style="height: 100px; resize: none; font-weight: normal; width: 800px; " placeholder="Qual foi o principal impeditivo?">
                                    </textarea>


                                </div>

                                <div v-else-if="item.ordem === 2 && pergunta.ordem === 8">
                                    <label :for="'opcao-' + item.idPesquisaPerguntaResposta"
                                        :class="{ 'custom-label': true, 'custom-label-selected': respostasSelecionadas[pergunta.idPesquisaPergunta] === item.idPesquisaPerguntaResposta }">
                                        <input style="display: none;" type="radio"
                                            :id="'opcao-' + item.idPesquisaPerguntaResposta"
                                            :name="'opcao-' + pergunta.idPesquisaPergunta"
                                            :value="item.idPesquisaPerguntaResposta"
                                            @change="selectResposta(pergunta, item)" />
                                            Não
                                        </label>
                              
                                    <!-- <textarea class="desk120too" v-if="Object.values(respostasSelecionadas).includes(item.idPesquisaPerguntaResposta)"
                                     v-model="respostasComplementos[pergunta.idPesquisaPergunta]"
                                    style="height: 100px; resize: none; font-weight: normal; width: 800px; " placeholder="Qual foi o principal impeditivo?">
                                    </textarea> -->
                                </div>

                                <!-- Resposta 9 -->
                                <div v-else-if="item.ordem === 1 && pergunta.ordem === 9">
                                    <label :for="'opcao-' + item.idPesquisaPerguntaResposta"
                                        :class="{ 'custom-label': true, 'custom-label-selected': respostasSelecionadas[pergunta.idPesquisaPergunta] === item.idPesquisaPerguntaResposta }">
                                        <input style="display: none;" type="radio"
                                            :id="'opcao-' + item.idPesquisaPerguntaResposta"
                                            :name="'opcao-' + pergunta.idPesquisaPergunta"
                                            :value="item.idPesquisaPerguntaResposta"
                                            @change="selectResposta(pergunta, item)" />
                                            {{ item.descricao }}
                                        </label>
                                        <p class="" v-if="mensagensErro[pergunta.idPesquisaPergunta]" style="color: red; font-size: 12px; width: 300px;">
                                            {{ mensagensErro[pergunta.idPesquisaPergunta] }}
                                        </p>                                       
                                    <textarea class="" v-if="Object.values(respostasSelecionadas).includes(item.idPesquisaPerguntaResposta + 3)"
                                     v-model="respostasComplementos[pergunta.idPesquisaPergunta]"
                                    style="height: 100px; resize: none; font-weight: normal; width: 800px; " placeholder="Qual?">
                                    </textarea>
                                </div>

                                <div v-else-if="item.ordem === 4 && pergunta.ordem === 9">
                                    <label :for="'opcao-' + item.idPesquisaPerguntaResposta"
                                        :class="{ 'custom-label': true, 'custom-label-selected': respostasSelecionadas[pergunta.idPesquisaPergunta] === item.idPesquisaPerguntaResposta }">
                                        <input style="display: none;" type="radio"
                                            :id="'opcao-' + item.idPesquisaPerguntaResposta"
                                            :name="'opcao-' + pergunta.idPesquisaPergunta"
                                            :value="item.idPesquisaPerguntaResposta"
                                            @change="selectResposta(pergunta, item)" />
                                            Outro
                                        </label>
                                        <!-- <p class="" v-if="mensagensErro[pergunta.idPesquisaPergunta]" style="color: red; font-size: 12px; width: 300px;">
                                            {{ mensagensErro[pergunta.idPesquisaPergunta] }}
                                        </p>                                       
                                    <textarea class="" v-if="Object.values(respostasSelecionadas).includes(item.idPesquisaPerguntaResposta + 3)"
                                     v-model="respostasComplementos[pergunta.idPesquisaPergunta]"
                                    style="height: 100px; resize: none; font-weight: normal; width: 800px; " placeholder="Qual?">
                                    </textarea> -->
                                </div>

                                 <!-- Resposta 12-->
                                <div v-else-if="item.ordem === 1 && pergunta.ordem === 12">
                                    <label :for="'opcao-' + item.idPesquisaPerguntaResposta"
                                        :class="{ 'custom-label': true, 'custom-label-selected': respostasSelecionadas[pergunta.idPesquisaPergunta] === item.idPesquisaPerguntaResposta }">
                                        <input style="display: none;" type="radio"
                                            :id="'opcao-' + item.idPesquisaPerguntaResposta"
                                            :name="'opcao-' + pergunta.idPesquisaPergunta"
                                            :value="item.idPesquisaPerguntaResposta"
                                            @change="selectResposta(pergunta, item)" />
                                            Sim
                                        </label>
                                        <p v-if="mensagensErro[pergunta.idPesquisaPergunta]" style="color: red; font-size: 12px; width: 300px;">
                                            {{ mensagensErro[pergunta.idPesquisaPergunta] }}
                                        </p>                                       
                                    <textarea class="" v-if="Object.values(respostasSelecionadas).includes(item.idPesquisaPerguntaResposta)"
                                     v-model="respostasComplementos[pergunta.idPesquisaPergunta]"
                                    style="height: 100px; resize: none; font-weight: normal; width: 800px; " placeholder="Qual?">
                                    </textarea>
                                </div>

                                <!-- Resposta 13-->
                                <div v-else-if="item.ordem === 1 && pergunta.ordem === 13">
                                    <label :for="'opcao-' + item.idPesquisaPerguntaResposta"
                                        :class="{ 'custom-label': true, 'custom-label-selected': respostasSelecionadas[pergunta.idPesquisaPergunta] === item.idPesquisaPerguntaResposta }">
                                        <input style="display: none;" type="radio"
                                            :id="'opcao-' + item.idPesquisaPerguntaResposta"
                                            :name="'opcao-' + pergunta.idPesquisaPergunta"
                                            :value="item.idPesquisaPerguntaResposta"
                                            @change="selectResposta(pergunta, item)" />
                                            Sim
                                        </label>
                                        <p v-if="mensagensErro[pergunta.idPesquisaPergunta]" style="color: red; font-size: 12px; width: 300px;">
                                            {{ mensagensErro[pergunta.idPesquisaPergunta] }}
                                        </p>                                       
                                    <textarea class="" v-if="Object.values(respostasSelecionadas).includes(item.idPesquisaPerguntaResposta)"
                                     v-model="respostasComplementos[pergunta.idPesquisaPergunta]"
                                    style="height: 100px; resize: none; font-weight: normal; width: 800px; " placeholder="O que diria sobre ele?">
                                    </textarea>
                                    <textarea class="" v-if="Object.values(respostasSelecionadas).includes(item.idPesquisaPerguntaResposta + 1)"
                                     v-model="respostasComplementos[pergunta.idPesquisaPergunta]"
                                    style="height: 100px; resize: none; font-weight: normal; width: 800px; " placeholder="O que precisaríamos melhorar para que você recomendasse?">
                                    </textarea>
                                </div>

                                <div v-else-if="item.ordem === 2 && pergunta.ordem === 13">
                                    <label :for="'opcao-' + item.idPesquisaPerguntaResposta"
                                        :class="{ 'custom-label': true, 'custom-label-selected': respostasSelecionadas[pergunta.idPesquisaPergunta] === item.idPesquisaPerguntaResposta }">
                                        <input style="display: none;" type="radio"
                                            :id="'opcao-' + item.idPesquisaPerguntaResposta"
                                            :name="'opcao-' + pergunta.idPesquisaPergunta"
                                            :value="item.idPesquisaPerguntaResposta"
                                            @change="selectResposta(pergunta, item)" />
                                            Não
                                        </label>                                    
                                    <!-- <textarea class="desk120too" v-if="Object.values(respostasSelecionadas).includes(item.idPesquisaPerguntaResposta)"
                                     v-model="respostasComplementos[pergunta.idPesquisaPergunta]"
                                    style="height: 100px; resize: none; font-weight: normal; width: 800px; " placeholder="O que precisaríamos melhorar para que você recomendasse?">
                                    </textarea> -->
                                </div>

                                <!-- Resposta 16-->
                                <div v-else-if="item.ordem === 5 && pergunta.ordem === 16">
                                    <label :for="'opcao-' + item.idPesquisaPerguntaResposta"
                                        :class="{ 'custom-label': true, 'custom-label-selected': respostasSelecionadas[pergunta.idPesquisaPergunta] === item.idPesquisaPerguntaResposta }">
                                        <input style="display: none;" type="radio"
                                            :id="'opcao-' + item.idPesquisaPerguntaResposta"
                                            :name="'opcao-' + pergunta.idPesquisaPergunta"
                                            :value="item.idPesquisaPerguntaResposta"
                                            @change="selectResposta(pergunta, item)" />
                                            Outros
                                        </label>
                                        <!-- <p class="desk475" v-if="mensagensErro[pergunta.idPesquisaPergunta]" style="color: red; font-size: 12px; width: 300px;">
                                            {{ mensagensErro[pergunta.idPesquisaPergunta] }}
                                        </p>                                       
                                    <textarea class="desk475" v-if="Object.values(respostasSelecionadas).includes(item.idPesquisaPerguntaResposta)"
                                     v-model="respostasComplementos[pergunta.idPesquisaPergunta]"
                                    style="height: 100px; resize: none; font-weight: normal; width: 800px; " placeholder="Quais?">
                                    </textarea> -->
                                </div>

                                <div v-else-if="item.ordem === 1 && pergunta.ordem === 16">
                                    <label :for="'opcao-' + item.idPesquisaPerguntaResposta"
                                        :class="{ 'custom-label': true, 'custom-label-selected': respostasSelecionadas[pergunta.idPesquisaPergunta] === item.idPesquisaPerguntaResposta }">
                                        <input style="display: none;" type="radio"
                                            :id="'opcao-' + item.idPesquisaPerguntaResposta"
                                            :name="'opcao-' + pergunta.idPesquisaPergunta"
                                            :value="item.idPesquisaPerguntaResposta"
                                            @change="selectResposta(pergunta, item)" />
                                            {{item.descricao}}
                                        </label>
                                        <p class="" v-if="mensagensErro[pergunta.idPesquisaPergunta]" style="color: red; font-size: 12px; width: 300px;">
                                            {{ mensagensErro[pergunta.idPesquisaPergunta] }}
                                        </p>                                       
                                    <textarea class="" v-if="Object.values(respostasSelecionadas).includes(item.idPesquisaPerguntaResposta + 4)"
                                     v-model="respostasComplementos[pergunta.idPesquisaPergunta]"
                                    style="height: 100px; resize: none; font-weight: normal; width: 800px; " placeholder="Quais?">
                                    </textarea>
                                </div>

                                
                                <!-- Resposta 18-->
                                <div v-else-if="item.ordem === 1 && pergunta.ordem === 18">
                                    <label :for="'opcao-' + item.idPesquisaPerguntaResposta"
                                        :class="{ 'custom-label': true, 'custom-label-selected': respostasSelecionadas[pergunta.idPesquisaPergunta] === item.idPesquisaPerguntaResposta }">
                                        <input style="display: none;" type="radio"
                                            :id="'opcao-' + item.idPesquisaPerguntaResposta"
                                            :name="'opcao-' + pergunta.idPesquisaPergunta"
                                            :value="item.idPesquisaPerguntaResposta"
                                            @change="selectResposta(pergunta, item)" />
                                            {{item.descricao}}
                                        </label>
                                        <p v-if="mensagensErro[pergunta.idPesquisaPergunta]" style="color: red; font-size: 12px; width: 300px;">
                                            {{ mensagensErro[pergunta.idPesquisaPergunta] }}
                                        </p>                                       
                                    <textarea class="" v-if="Object.values(respostasSelecionadas).includes(item.idPesquisaPerguntaResposta + 1)"
                                     v-model="respostasComplementos[pergunta.idPesquisaPergunta]"
                                    style="height: 100px; resize: none; font-weight: normal; width: 800px; " placeholder="Qual a sugestão?">
                                    </textarea>
                                </div>

                                <div v-else-if="item.ordem === 2 && pergunta.ordem === 18">
                                    <label :for="'opcao-' + item.idPesquisaPerguntaResposta"
                                        :class="{ 'custom-label': true, 'custom-label-selected': respostasSelecionadas[pergunta.idPesquisaPergunta] === item.idPesquisaPerguntaResposta }">
                                        <input style="display: none;" type="radio"
                                            :id="'opcao-' + item.idPesquisaPerguntaResposta"
                                            :name="'opcao-' + pergunta.idPesquisaPergunta"
                                            :value="item.idPesquisaPerguntaResposta"
                                            @change="selectResposta(pergunta, item)" />
                                            {{item.descricao}}
                                        </label>

                                </div>






                                <!-- até aqui -->



                            

                                <div v-else-if="!item.complemento">
                                    <label :for="'opcao-' + item.idPesquisaPerguntaResposta"
                                        :class="{ 'custom-label': true, 'custom-label-selected': respostasSelecionadas[pergunta.idPesquisaPergunta] === item.idPesquisaPerguntaResposta }">
                                        <input style="display: none;" type="radio"
                                            :id="'opcao-' + item.idPesquisaPerguntaResposta"
                                            :name="'opcao-' + pergunta.idPesquisaPergunta"
                                            :value="item.idPesquisaPerguntaResposta"
                                            @change="selectResposta(pergunta, item)" />
                                        {{   item.descricao === 'Se sim, como foi a experiência?' ? 'Sim' : item.descricao === 'Se não, qual foi o principal impeditivo?' ? 'Não' : item.descricao  }}
                                    </label>

                                </div>





                                <div v-else style="width: 800px; min-width: none;" class="celular">
                                    <p v-if="mensagensErro[pergunta.idPesquisaPergunta]" style="color: red; font-size: 12px; width: 300px;">
        {{ mensagensErro[pergunta.idPesquisaPergunta] }}
    </p>

                                    <textarea v-model="respostasComplementos[pergunta.idPesquisaPergunta]"
                                        style="height: 100px; resize: none; font-weight: normal;" placeholder="Escreva aqui"
                                        @input="selectResposta(pergunta, item)">
                                    </textarea>
                                   
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div style="display: flex; justify-content: center; flex-direction: column; align-items: center;">
                    <Notificacao
                :infos="notificacao_infos"
                v-if="notificacao_infos.mensagem"
              />
              <div v-if="digitarmais" style="display: flex;  box-shadow: 2px 3px 9px #00000033;padding: 5px ; border-radius: 7px; margin-bottom: 30px; width: 100%; justify-content: center;">
                    <span>Ops! Que tal adicionar mais alguns detalhes?</span>
                </div>
                    <div class="clacla">
                        <button v-if="fase !== 1" @click="backzone()" style="margin-right: 30px; background-color:#757575;"  class="btnnext rmcmargi">VOLTAR</button>
                        <button class="btnnext" @click="handleProximo">PRÓXIMO</button>
                    </div>
                    <span style="color: #757575; margin-top: 30px">*Resposta obrigatória</span>
                </div>
            </div>

            <div v-else style="margin-top: 30px; display: flex; flex-direction: column; align-items: center">
                <p style="font-weight: bold; text-align: center; font-size: 20px"><span
                        style="color:#00ABC8">{{capitalizeFirstLetter(primeiroNome)}},</span> agradecemos por sua participação!</p>
                <p style="font-weight: bold; font-size: 20px; text-align: center;">Aqui está seu cupom de <span style="color: #FF681E;">R$50,00</span> para usar em sua próxima compra na Stanley!</p>
            
                <div>
                <p style="font-weight: bold; margin-bottom: 0px; text-align: center">Código do Cupom*</p>
                <div style="display: flex; align-items: center; justify-content: space-around; border: #FF681E 2px solid; border-radius: 5px; padding: 6px; min-width: 200px">
                    <span style="font-weight: bold; font-size: 22px; ">{{ this.codigoCupom }}</span>
                    <img src="../assets/img/interface.png" style="width: 30px; cursor: pointer;" @click="copiarTexto">
                </div>
                </div>
            
                <a href="https://www.stanley1913.com.br/?utm_source=newbacon&utm_medium=email&utm_campaign=2024-jornada-pesquisa_satisfacao_legionarios-re-legionarios&utm_term=cadastrados&utm_content=cta_usar_cupom_agora-conversao" 
                style="margin-top: 25px; background-color: #FF681E; color: white; padding: 5px 40px; border: none; text-decoration: none;">USAR CUPOM</a>
            
            </div>
            <label v-if="pesquisaFinalizada" style="text-align: center; width: 100%; padding: 0px 15%; font-size: 10px; margin-top: 10%; padding-bottom: -50px;" for="">*Cupom válido para compras realizadas no site www.stanley1913.com.br até as 23h59min do dia 20/02/2025. Limite de 1 uso por CPF. Não aplicável para os produtos de Collabs e Cross Bottle. Não acumulativo com outras promoções do site, exceto Frete Grátis em compras acima de R$350.</label>

        </section>

        <TheFooter />
    </div>
</template>

<script>
import TheFooter from '@/components/Base/TheFooter.vue';
import { api } from '@/services';

export default {
    name: 'PesquisaSatisfacao',
    components: {
        TheFooter
    },
    data() {
        return {
            idparavaldiar: [],
            load: true,
            codigoCupom: null,
            lado: 0,
            fase: 1,
            pesquisaFinalizada: false,
            perguntas: null,
            resposta: [],
            idPesquisa: null,
            respostasSelecionadas: {}, 
            respostasComplementos: {}, 
            usuarioLogado: [],
            notificacao_infos: {
                variant: "info",
                mensagem: "",
                count_timer: 0,
            },
            digitarmais: false,
            mensagensErro: {},
        };
    },

    methods: {
        capitalizeFirstLetter(str) {
      if (!str) return str; 
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
        backzone(){
            this.load = true

            api
            .delete('/satisfacao')
            .then(()=>{
                this.getquestion();
                this.resposta = [];
                this.respostasSelecionadas = {};
                this.respostasComplementos = {};
            })
            .catch((error)=>{
                console.log(error)
            })
        },
        copiarTexto(){
            navigator.clipboard
        .writeText(this.dados)
        .then(() => {
        })
        .catch((err) => {
          console.error("Erro ao copiar texto:", err);
        });
        },
        selectResposta(idPesquisaPergunta, idResposta) {

            if (idPesquisaPergunta.ordem === 3 && idResposta.ordem === 2) {
    if (!this.idparavaldiar.includes(idResposta.idPesquisaPerguntaResposta)) {
        this.idparavaldiar.push(idResposta.idPesquisaPerguntaResposta);
    }
} else if (idPesquisaPergunta.ordem === 3 && idResposta.ordem === 1) {
    const index = this.idparavaldiar.indexOf(idResposta.idPesquisaPerguntaResposta + 1);
    if (index !== -1) {
        this.idparavaldiar.splice(index, 1);
    }
} else if (idPesquisaPergunta.ordem === 4 && idResposta.ordem === 1) {
    if (!this.idparavaldiar.includes(idResposta.idPesquisaPerguntaResposta)) {
        this.idparavaldiar.push(idResposta.idPesquisaPerguntaResposta);
    }
} else if (idPesquisaPergunta.ordem === 4 && idResposta.ordem === 2) {
    const index = this.idparavaldiar.indexOf(idResposta.idPesquisaPerguntaResposta - 1);
    if (index !== -1) {
        this.idparavaldiar.splice(index, 1);
    }
} else if (idPesquisaPergunta.ordem === 6 && idResposta.ordem === 1) {
    if (!this.idparavaldiar.includes(idResposta.idPesquisaPerguntaResposta)) {
        this.idparavaldiar.push(idResposta.idPesquisaPerguntaResposta);
    }
} else if (idPesquisaPergunta.ordem === 6 && idResposta.ordem === 2) {
    const index = this.idparavaldiar.indexOf(idResposta.idPesquisaPerguntaResposta - 1);
    if (index !== -1) {
        this.idparavaldiar.splice(index, 1);
    }
} else if (idPesquisaPergunta.ordem === 8 && idResposta.ordem === 1) {
    if (!this.idparavaldiar.includes(idResposta.idPesquisaPerguntaResposta)) {
        this.idparavaldiar.push(idResposta.idPesquisaPerguntaResposta);
    }
    const index = this.idparavaldiar.indexOf(idResposta.idPesquisaPerguntaResposta + 1);
    if (index !== -1) {
        this.idparavaldiar.splice(index, 1);
    }
} else if (idPesquisaPergunta.ordem === 8 && idResposta.ordem === 2) {
    if (!this.idparavaldiar.includes(idResposta.idPesquisaPerguntaResposta)) {
        this.idparavaldiar.push(idResposta.idPesquisaPerguntaResposta);
    }
    const index = this.idparavaldiar.indexOf(idResposta.idPesquisaPerguntaResposta - 1);
    if (index !== -1) {
        this.idparavaldiar.splice(index, 1);
    }
} else if (idPesquisaPergunta.ordem === 9 && idResposta.ordem === 4) {
    if (!this.idparavaldiar.includes(idResposta.idPesquisaPerguntaResposta)) {
        this.idparavaldiar.push(idResposta.idPesquisaPerguntaResposta);
    }
} else if (idPesquisaPergunta.ordem === 9 && idResposta.ordem === 3) {
    const index = this.idparavaldiar.indexOf(idResposta.idPesquisaPerguntaResposta + 1);
    if (index !== -1) {
        this.idparavaldiar.splice(index, 1);
    }
} else if (idPesquisaPergunta.ordem === 9 && idResposta.ordem === 2) {
    const index = this.idparavaldiar.indexOf(idResposta.idPesquisaPerguntaResposta + 2);
    if (index !== -1) {
        this.idparavaldiar.splice(index, 1);
    }
} else if (idPesquisaPergunta.ordem === 9 && idResposta.ordem === 1) {
    const index = this.idparavaldiar.indexOf(idResposta.idPesquisaPerguntaResposta + 3);
    if (index !== -1) {
        this.idparavaldiar.splice(index, 1);
    }
} else if (idPesquisaPergunta.ordem === 10) {
    this.idparavaldiar.push(idResposta.idPesquisaPerguntaResposta);
}  else if (idPesquisaPergunta.ordem === 12 && idResposta.ordem === 1) {
    if (!this.idparavaldiar.includes(idResposta.idPesquisaPerguntaResposta)) {
        this.idparavaldiar.push(idResposta.idPesquisaPerguntaResposta);
    }
} else if (idPesquisaPergunta.ordem === 12 && idResposta.ordem === 2) {
    const index = this.idparavaldiar.indexOf(idResposta.idPesquisaPerguntaResposta - 1);
    if (index !== -1) {
        this.idparavaldiar.splice(index, 1);
    }
} else if (idPesquisaPergunta.ordem === 13 && idResposta.ordem === 1) {
    if (!this.idparavaldiar.includes(idResposta.idPesquisaPerguntaResposta)) {
        this.idparavaldiar.push(idResposta.idPesquisaPerguntaResposta);
    }
    const index = this.idparavaldiar.indexOf(idResposta.idPesquisaPerguntaResposta + 1);
    if (index !== -1) {
        this.idparavaldiar.splice(index, 1);
    }
} else if (idPesquisaPergunta.ordem === 13 && idResposta.ordem === 2) {
    if (!this.idparavaldiar.includes(idResposta.idPesquisaPerguntaResposta)) {
        this.idparavaldiar.push(idResposta.idPesquisaPerguntaResposta);
    }
    const index = this.idparavaldiar.indexOf(idResposta.idPesquisaPerguntaResposta - 1);
    if (index !== -1) {
        this.idparavaldiar.splice(index, 1);
    }
}else if (idPesquisaPergunta.ordem === 16 && idResposta.ordem === 5) {
    if (!this.idparavaldiar.includes(idResposta.idPesquisaPerguntaResposta)) {
        this.idparavaldiar.push(idResposta.idPesquisaPerguntaResposta);
    }
} else if (idPesquisaPergunta.ordem === 16 && idResposta.ordem === 4) {
    const index = this.idparavaldiar.indexOf(idResposta.idPesquisaPerguntaResposta + 1);
    if (index !== -1) {
        this.idparavaldiar.splice(index, 1);
    }
} else if (idPesquisaPergunta.ordem === 16 && idResposta.ordem === 3) {
    const index = this.idparavaldiar.indexOf(idResposta.idPesquisaPerguntaResposta + 2);
    if (index !== -1) {
        this.idparavaldiar.splice(index, 1);
    }
} else if (idPesquisaPergunta.ordem === 16 && idResposta.ordem === 2) {
    const index = this.idparavaldiar.indexOf(idResposta.idPesquisaPerguntaResposta + 3);
    if (index !== -1) {
        this.idparavaldiar.splice(index, 1);
    }
} else if (idPesquisaPergunta.ordem === 16 && idResposta.ordem === 1) {
    const index = this.idparavaldiar.indexOf(idResposta.idPesquisaPerguntaResposta + 4);
    if (index !== -1) {
        this.idparavaldiar.splice(index, 1);
    }
}else if (idPesquisaPergunta.ordem === 17) {
    this.idparavaldiar.push(idResposta.idPesquisaPerguntaResposta);
}    else if (idPesquisaPergunta.ordem === 18 && idResposta.ordem === 2) {
    if (!this.idparavaldiar.includes(idResposta.idPesquisaPerguntaResposta)) {
        this.idparavaldiar.push(idResposta.idPesquisaPerguntaResposta);
    }
} else if (idPesquisaPergunta.ordem === 18 && idResposta.ordem === 1) {
    const index = this.idparavaldiar.indexOf(idResposta.idPesquisaPerguntaResposta + 1);
    if (index !== -1) {
        this.idparavaldiar.splice(index, 1);
    }
}

            
            this.$set(this.respostasSelecionadas, idPesquisaPergunta.idPesquisaPergunta, idResposta.idPesquisaPerguntaResposta);

            this.setQuestion(idPesquisaPergunta.idPesquisaPergunta, idResposta.idPesquisaPerguntaResposta);
        },
        setQuestion(idPesquisaPergunta, idResposta, complemento = null) {
            const index = this.resposta.findIndex(
                (res) => res.idPesquisaPergunta === idPesquisaPergunta
            );

            const dados = {
                idPesquisa: this.idPesquisa,
                idRespostas: idResposta ? [idResposta] : [],
                idPesquisaPergunta: idPesquisaPergunta,
                complemento: complemento || null,
            };

            if (index === -1) {
                // Adiciona a resposta se não existir
                this.resposta.push(dados);
            } else {
                // Atualiza a resposta existente
                this.resposta[index] = dados;
            }
        },
        handleProximo() {
            
            this.perguntas.forEach((pergunta) => {
                const complemento = this.respostasComplementos[pergunta.idPesquisaPergunta];
                const idResposta = this.respostasSelecionadas[pergunta.idPesquisaPergunta] || null;

                const index = this.resposta.findIndex(
                    (res) => res.idPesquisaPergunta === pergunta.idPesquisaPergunta
                );

                if (index !== -1) {
                    // Atualiza o complemento e o idRespostas, se necessário
                    this.resposta[index].complemento = complemento || null;
                    this.resposta[index].idRespostas = idResposta ? [idResposta] : [];
                } else {
                    // Adiciona uma nova resposta se não existir
                    this.resposta.push({
                        idPesquisa: this.idPesquisa,
                        idPesquisaPergunta: pergunta.idPesquisaPergunta,
                        idRespostas: idResposta ? [idResposta] : [],
                        complemento: complemento || null,
                    });
                }
            });

            
            this.validarRespostas();
        },
        
        
        validarRespostas() {
    this.mensagensErro = {}; // Reseta as mensagens de erro

    const todasValidas = this.resposta.every((resp) => {
        const idsParaValidar = this.idparavaldiar;

        if (Array.isArray(resp.idRespostas) && resp.idRespostas.length === 0) {
            if (!resp.complemento || !/[a-zA-Z]/.test(resp.complemento)) {
                // this.$set(this.mensagensErro, resp.idPesquisaPergunta, "Campo obrigatório.");
                this.notificacao_infos.mensagem = 'Obrigatório responder todas as perguntas.'
                setTimeout(() => {
                    this.notificacao_infos.mensagem = null
                }, 3000);
                return false;
            }
            if (resp.complemento.length < 10) {
                this.$set(this.mensagensErro, resp.idPesquisaPergunta, "Ops! Que tal adicionar mais alguns detalhes?");
                return false;
            }
        }

        if (resp.idRespostas.some((id) => idsParaValidar.includes(id))) {
            if (!resp.complemento || !/[a-zA-Z]/.test(resp.complemento)) {
                this.$set(this.mensagensErro, resp.idPesquisaPergunta, "Campo obrigatório.");
                return false;
            }
            if (resp.complemento.length < 10) {
                this.$set(this.mensagensErro, resp.idPesquisaPergunta, "Ops! Que tal adicionar mais alguns detalhes?");
                return false;
            }
        }

        return true;
    });

    if (todasValidas) {
        this.poste();
    }
},



        poste(){
            this.load = true
            api
            .post('/satisfacao',this.resposta )
            .then(()=>{
                this.getquestion()
                this.resposta = []
            })
            .catch((error)=>{
                console.log(error)
            })
        },
        getquestion() {
            api
                .get('/satisfacao')
                .then((resp) => {
                    this.perguntas = resp.data.perguntas;
                    this.fase = resp.data.pagina;
                    this.idPesquisa = resp.data.idPesquisa;
                    this.lado = this.fase * 20;
                    this.pesquisaFinalizada = resp.data.faseEncerrada
                    if(this.pesquisaFinalizada){
                        this.lado = 100
                    }
                    this.codigoCupom = resp.data.cupom || null

                    setTimeout(() => {
                        this.load = false
                    }, 600);
                })
                .catch((error) => {
                    console.error(error);
                    this.pesquisaFinalizada = true
                });
        },
        buscarUsuarios() {
            this.loading = true;
            api
                .get('/usuario/logged')
                .then((resp) => {
                    this.usuarioLogado = resp.data;
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
    },
    mounted() {
        this.getquestion();
        this.buscarUsuarios();
    },
    computed: {
        primeiroNome() {
            if (this.usuarioLogado.nome) {
                const nomeCompleto = this.usuarioLogado.nome;
                const primeiroNome = nomeCompleto.split(' ')[0];
                return primeiroNome;
            } else {
                return '';
            }
        },

    },
};
</script>



<style>



textarea{
    background-color: #ffffff70;
}



@media(max-width: 900px) {
    .desk {
        display: none;
    }

    .clacla{
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    gap: 10px;
}

    .rmcmargi{
        margin-right: 0px !important;
    }

    .moboption {
        font-size: 20px !important
    }

    .mobilete{
        flex-direction: column;
        width: 100% !important;
    }

    .aboutwidth{
        width: auto !important;
        max-width: 100% !important;
        min-width: none;
    }

    .celular{
        width: 100% !important;
    }

    textarea{
        width: 100% !important;
    }

}

@media(min-width: 901px) {
    .iconnns{
    background-image: url('../assets/img/icone-indique2.png');
    background-position: left bottom; 
  background-repeat: no-repeat;
  background-size: 25%;
  background-origin: border-box;
  background-color: rgba(255, 255, 255, 0.6);
  background-blend-mode: overlay; 
  min-height: 90vh;
}
    .mob {
        display: none;
    }

    .desk120{
        margin-left: -115%;
    }

    .desk120too{
        margin-left: -115%;
        margin-top: 30px;
    }

    .desk475{
        margin-left: -475%;
    }

    .desk500{
        margin-left: -360%;
    }


}

.btnnext {
    background-color: #FF681E;
    padding: 7px 60px;
    color: white;
    border: none;
    border-radius: 2px;
    box-shadow: 0px 0px 1px 0.8px rgba(0, 0, 0, 0.356);
}

/* Estilo padrão do label */
.custom-label {
    border: 2px solid black;
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.3s ease;
    text-align: center;
    line-height: 0.9;
    padding: 1px 3px
}

.aboutwidth {
    width: 180px;
    max-width: 20%;
    min-width: 20%;
}

/* Estilo do label selecionado */
.custom-label-selected {
    border-color: #00ABC8;
    background-color: #00ABC8;
    color: white;
    font-weight: bold;
    text-align: center;

}









.loader {
  border: 7px solid #00ABC8;
  border-left-color: transparent;
  border-radius: 50%;
}

.loader {
  border: 7px solid #00ABC8;
  border-left-color: transparent;
  width: 100px;
  height: 100px;
}

.loader {
  border: 7px solid #00ABC8;
  border-left-color: transparent;
  width: 100px;
  height: 100px;
  animation: spin89345 1s linear infinite;
}

@keyframes spin89345 {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

</style>
