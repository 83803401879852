<template>
  <div>
    <section class="login" v-if="!sucesso">

      <div class="loading_global" v-if="loading">
          <span class="loader"></span>
      </div>

      <div v-else>
        <div class="form-login" data-anima="top">
          <router-link to="/">
          <img
            src="@/assets/img/logo_legionarios_preto.svg"
            alt="Logo Stanley Legionários"
            class="logo"
          /></router-link>
          <form @submit.prevent="submitForm"> 
            <div v-if="etapa === 1">
              <h1>FAÇA PARTE DA <strong class="strong">LEGIÃO</strong></h1>
              <Notificacao
                  :infos="notificacao_infos"
                  v-if="notificacao_infos.mensagem"
                />
              <label for="email">Endereço de e-mail</label>
              <input
                type="email"
                id="email"
                name="email"
                v-model="form.email"
                required
              /><br /><br />

              <label for="email">Confirmação de e-mail</label>
              <input
                type="email"
                id="ConfirmEmail"
                name="ConfirmEmail"
                v-model="confirmEmail"
                required
                onpaste="return false"
                ondrop="return false"
              /><br /><br />

              <div class="btn-desabilitado">
                <button
                  v-if="etapa < totalEtapas"
                  v-on:click="emailValido"
                  :class="{ 'btn-disabled': !verificarEmailPreenchido() }"
                  :disabled="!verificarEmailPreenchido()"
                  class="btn-avancar"
                >
                  AVANÇAR
                </button>
              </div>
            </div>

            <div v-if="etapa === 2" data-anima="left">
              <div class="espaco"></div>
              <div class="linha-etapa etapa-senha"></div>
              <div class="linha-cinza"></div>
              <div class="linha-etapa etapa-senha"></div>
              <div class="voltar">
                <button
                  type="button"
                  v-if="etapa > 1"
                  v-on:click="anteriorEtapa"
                  class="anterior"
                >
                  <img
                    src="@/assets/img/left.png"
                    alt="icone seta para esquerda"
                    class="seta"
                  />
                </button>
                <p><span>Etapa 1 de 3</span> <br />Crie sua senha</p>
              </div>

              <label for="senha">Senha</label>
              <div class="campo-senha">
                <input
                  :type="mostrarSenha ? 'text' : 'password'"
                  id="senha"
                  name="senha"
                  v-model="form.senha"
                  @input="verificarSenhaPreenchida"
                  required
                /><br /><br />
                <button @click.prevent="toggleMostrarSenha" class="btn-view">
                  <img
                    v-if="mostrarSenha"
                    src="@/assets/img/noview.png"
                    alt="Icone de visualização"
                    class="view"
                  />
                  <img
                    v-else
                    src="@/assets/img/view.png"
                    alt="Icone de visualização"
                    class="view"
                  />
                </button>
              </div>
              <br>
              <label for="confirmar-senha" class="confirmar-senha">Confirmar senha</label>
                    <div class="campo-senha confirmarSenha">
                        <input :type="mostrarConfirmarSenha ? 'text' : 'password'" id="confirmacaosenha" v-model="confirmacaoSenha" required onpaste="return false"  ondrop="return false"><br><br>
                        <div @click.prevent="toggleMostrarConfirmarSenha" class="btn-view">
                                <img v-if="mostrarConfirmarSenha"
                                    src="@/assets/img/noview.png"
                                    alt="Icone de visualização"
                                    class="view"
                                />
                                <img v-else
                                    src="@/assets/img/view.png"
                                    alt="Icone de visualização"
                                    class="view"
                                />
                            </div>
                    </div>

              <p class="txtregra">A senha deve conter pelo menos:</p>
              <p :class="verificarSenhaPreenchida().letra">- 1 Letra maiúscula</p>
              <p :class="verificarSenhaPreenchida().numero">- 1 Número</p>
              <p :class="verificarSenhaPreenchida().caracterEspecial">
                - 1 Caractere especial (ex:#?!)
              </p>
              <div class="btn-senha">
                <button
                  v-if="etapa < totalEtapas"
                  v-on:click="proximaEtapa"
                  :class="{
                    'btn-disabled': !verificarSenhaPreenchidaCorretamente()
                  }"
                  :disabled="!verificarSenhaPreenchidaCorretamente()"
                  class="btn-avancar"
                >
                  AVANÇAR
                </button>
              </div>
            </div>

            <div v-if="etapa === 3" data-anima="left">
              <div class="espaco"></div>
              <div class="linha-etapa etapa-cpf"></div>
              <div class="linha-cinza"></div>
              <div class="linha-etapa etapa-cpf"></div>
              <div class="voltar">
                <button
                  type="button"
                  v-if="etapa > 1"
                  v-on:click="anteriorEtapa"
                  class="anterior"
                >
                  <img
                    src="@/assets/img/left.png"
                    alt="icone seta para esquerda"
                    class="seta"
                  />
                </button>
                <p><span>Etapa 2 de 3</span> <br />Informe seu nome e cpf</p>
              </div>
              <div v-if="msgCpf" class="msgCpf">CPF já cadastrado! </div>

              <label for="nome" class="label">Nome completo</label>
              <input type="text" id="nome" name="nome" v-model="form.nome" required />
              <label for="senha" style="margin-top: 20px;">CPF</label>
              <div>
                <the-mask
                  type="text"
                  id="cpf"
                  name="cpf"
                  v-model="form.cpf"
                  :mask="'###.###.###-##'"
                  :masked="false"
                  inputmode="numeric"
                  required
                />

                <br /><br />
              </div>
              <div>
                <button
                  @click="cpfValido"
                  class="btn-avancar"
                  :class="{ 'btn-disabled': !validaDocumento() }"
                  :disabled="!validaDocumento()"
                >
                  AVANÇAR
                </button>
              </div>
            </div>

            <div v-if="etapa === 4" data-anima="right">
              <div class="espaco"></div>
              <div class="linha-etapa etapa-final"></div>
              <div class="linha-etapa etapa-final"></div>
              <div class="voltar">
                <button
                type="button"
                  v-if="etapa > 1"
                  v-on:click="anteriorEtapa"
                  class="anterior"
                >
                  <img
                    src="@/assets/img/left.png"
                    alt="icone seta para esquerda"
                    class="seta"
                  />
                </button>
                <p><span>Etapa 3 de 3</span> <br />Fale de você</p>
              </div>

              <Notificacao
                  :infos="notificacao_infos"
                  v-if="notificacao_infos.mensagem"
                />

              <label for="celular" class="label">Celular</label>

              <the-mask
                type="text"
                id="celular"
                name="celular"
                v-model="form.celular"
                :mask="'(##) #####-####'"
                inputmode="numeric"
                required
                :masked="false"
              />

              <label for="datanasc" class="label">Data de nascimento</label>
              <div class="data-nasc">
                <input
                  type="date"
                  id="dataNascimento"
                  name="dataNascimento"
                  placeholder="00/00/0000"
                  v-model="form.dataNascimento"
                  required
                />
              </div>

           <label for="cep">CEP</label>

           <the-mask
              type="text"
              id="cep"
              name="cep"
              v-model="form.cep"
              :mask="'#####-###'"
              inputmode="numeric"
              :masked="false"
              required
           />
                          
            <label for="genero" class="label label-genero">Como você se identifica:</label>

              <div class="generos">
                <div class="grupo1">
                  <input
                    type="radio"
                    id="homem"
                    name="genero"
                    value="homem"
                    required  
                    v-model="form.genero"
                  />
                  <label for="homem">Homem</label>

                  <input
                    type="radio"
                    id="mulher"
                    name="genero"
                    value="mulher"
                    v-model="form.genero"
                  />
                  <label for="mulher">Mulher</label>

                  <input
                    type="radio"
                    id="naobinario"
                    name="genero"
                    value="nao binario"
                    v-model="form.genero"
                  />
                  <label for="naobinario">Não binário</label>
                </div>

                <div class="grupo2">
                  <input
                    type="radio"
                    id="outro"
                    name="genero"
                    value="outro"
                    v-model="form.genero"
                  />
                  <label for="outro">Outro</label>

                  <input
                    type="radio"
                    id="nao"
                    name="genero"
                    value="Prefiro nao dizer"
                    v-model="form.genero"
                  />
                  <label for="naodizer">Prefiro não dizer</label>
                </div>
              </div>

              <label for="termos" class="label">Termos e condições</label>

              <div class="checkbox-form">
                <input
                  type="checkbox"
                  id="termo"
                  name="termo"
                  value="termos"
                  required
                />
                <label for="termo"
                  >Eu concordo com o Termos e Condições de Uso do Portal Legionários</label ><br />
              </div>

              <div v-if="!checks.optout">
                <div class="checkbox-form" v-if="!checks.optin">
                  <input
                    type="checkbox"
                    id="termo"
                    name="termo"
                    value="optin"
                    v-model="form.optin"
                    
                  />
                  <label for="termo"
                    >Quero receber novidades e promoções exclusivas da Stanley por e-mail.</label><br />
                </div>
                

                <div class="checkbox-form" v-if="!checks.optinSms">
                  <input
                    type="checkbox"
                    id="aceito"
                    name="aceito"
                    value="optinSms"
                    v-model="form.optinSms"
                  />
                  <label for="aceito"
                    >Quero receber novidades e promoções exclusivas da Stanley por SMS e Whatsapp.</label
                  ><br />
                </div>

                <p class="txt-termos">Ao fornecer suas informações você autoriza a STANLEY a realizar o tratamento de seus dados pessoais. Assim, a partir desta ação, 
                  você consente que a STANLEY envie e-mail, SMS ou WhatsApp com promoções, newsletter e informações sobre parcerias. Você declara 
                  também ter conhecimento e concordar com a nossa Política de Privacidade. 
                  <a href="https://atendimentostanley.zendesk.com/hc/pt-br/sections/360010713671" target="_blank">Saiba Mais.</a></p>

              </div>

              <button
                class="btn-avancar margin-btn">
                FINALIZAR
              </button>
            </div>
          </form>

          <div v-if="etapa == 1">
            <p class="txtcadastro">
              Já tem uma conta?
              <strong>
                <router-link to="/login">Login</router-link>
              </strong>
            </p>
          <!--  
            <div class="box-linhas">
              <div class="linha"></div>
              <div><p class="txt">ou</p></div>
              <div class="linha"></div>
            </div>-->

          </div>

        </div>
        <TheFooter />
      </div>
      
  </section>

   <section class="sucesso" v-else>
         <img
                src="@/assets/img/logo_legionarios_branca.svg"
                alt="Logo Stanley Legionários"
                class="logo desk"
        />

        <img
                src="@/assets/img/logo_legionarios_preto.svg"
                alt="Logo Stanley Legionários"
                class="logo mob"
        />
        <div class="conteudo-sucesso" data-anima="bottom">
          <h1>INSCRIÇÃO REALIZADA COM <strong>SUCESSO!</strong></h1>
          <p>Enviamos um e-mail de confirmação, verifique sua caixa postal e comece a explorar.</p>

          <router-link to="/login" style="text-decoration: none;" >
            <button type="button" class="btn-login">LOGIN</button>
          </router-link>
        </div>
    </section>
    
  </div>
</template>

<script>
import TheFooter from "@/components/Base/TheFooter.vue";
import { api } from "@/services.js";
import { TheMask } from "vue-the-mask";
import validateCPF from "@/validator/cpf.js";

export default {
  name: "Cadastro",
  components: {
    TheMask,
    TheFooter
  },
  data() {
    return {
    notificacao_infos: {
        variant: "info",
        mensagem: "",
        count_timer: 0,
    },
    msgCpf: false,
    sucesso: false,
    loading: false,
    checks: {
      optin: false,
      optinSms: false,
      optout: false
    },
    mostrarConfirmarSenha: false,
    mostrarSenha: false,
    etapa: 1,
    totalEtapas: 4,
    erroCadastro: '',
    errorCpf: false,
    confirmEmail: "",
    confirmacaoSenha: "",
    form: {
        email: "",
        senha: "",
        cpf: "",
        nome: "",
        celular: "",
        cep: "",
        endereco: "",
        cidade: "",
        uf: "",
        dataNascimento: "",
        genero: "",
        optin: false,
        optinSms: false,
        hashIndicacao: ""
      },
    };
  
  },
  methods: {
    verificarLetra() {
       const letraRegex = /[a-zA-Z]/;
        const possuiLetra = letraRegex.test(this.form.senha);
        const possuiLetraMaiuscula = /[A-Z]/.test(this.form.senha);

        if (possuiLetra && possuiLetraMaiuscula) {
            return "regra-valida";
        } else {
            return "regra-invalida";
        }
    },

    verificarNumero() {
      const numeroRegex = /[0-9]/;
      const possuiNumero = numeroRegex.test(this.form.senha);
      return possuiNumero ? "regra-valida" : "regra-invalida";
    },

    verificarCaracterEspecial() {
      const caracterEspecialRegex = /[!@#$%^&*(),.?":{}|<>]/;
      const possuiCaracterEspecial = caracterEspecialRegex.test(this.form.senha);
      return possuiCaracterEspecial ? "regra-valida" : "regra-invalida";
    },

    verificarSenhaPreenchida() {
      const classes = {
        letra: this.verificarLetra(),
        numero: this.verificarNumero(),
        caracterEspecial: this.verificarCaracterEspecial(),
      };

      return classes;
    },

    verificarSenhaPreenchidaCorretamente() {
      const letraRegex = /[a-zA-Z]/;
      const numeroRegex = /[0-9]/;
      const caracterEspecialRegex = /[!@#$%^&*(),.?":{}|<>]/;
      const LetraMaiusculaRegex = /[A-Z]/;

      const possuiLetra = letraRegex.test(this.form.senha);
      const possuiNumero = numeroRegex.test(this.form.senha);
      const possuiCaracterEspecial = caracterEspecialRegex.test(this.form.senha);
      const possuiLetraMaiuscula = LetraMaiusculaRegex.test(this.form.senha);

      const senhasIguais = this.form.senha === this.confirmacaoSenha;

      return possuiLetra && possuiNumero && possuiCaracterEspecial && possuiLetraMaiuscula && senhasIguais;
    },

    verificarEmailPreenchido() {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(this.form.email) && this.form.email === this.confirmEmail;
    },

    proximaEtapa() {
      if (this.etapa < this.totalEtapas) {
        this.etapa++;
      }
    },
    anteriorEtapa() {
      if (this.etapa > 1) {
        this.etapa--;
      }
    },
     limparMensagemErro() {
      this.notificacao_infos = {
        variant: "info",
        mensagem: "",
        count_timer: 0,
      };
    },

    toggleMostrarConfirmarSenha() {
      this.mostrarConfirmarSenha = !this.mostrarConfirmarSenha;
    },

    toggleMostrarSenha() {
      this.mostrarSenha = !this.mostrarSenha;
    },
    
    validaDocumento() {
      this.errorCpf = validateCPF(this.form.cpf);
      return this.errorCpf;
    },

    cpfValido() {
      this.loading = true;
      api
        .get(`/usuario/valid-cpf?cpf=${this.form.cpf}`)
        .then((resp) => {
          console.log(resp);
           if (resp.data === "CPF existe") {
              this.msgCpf = true;
            } else {
              this.msgCpf = false;
              this.proximaEtapa();
            }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    optinValido() {
      this.loading = true;
      api
        .get(`/usuario/valid-optin?email=${this.form.email}`)
        .then((resp) => {
          this.checks = resp.data;
          this.form.optin = resp.data.optin;
          this.form.optinSms = resp.data.optinSms;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    emailValido() {
      this.limparMensagemErro();
      this.loading = true;
      api
        .get(`/usuario/valid-email?email=${this.form.email}`)
        .then((resp) => {
          this.emails = resp.data;
          this.optinValido();
          if (this.etapa < this.totalEtapas) {
              this.etapa++;
          }
        })
        .catch((error) => {
          console.log(error)
          this.notificacao_infos = {
              variant: "error",
              mensagem: error.response
              ? error.response.data.errors[0]?.message
              : "Algo deu errado na requisição",
              count_timer: 5,
          };
        })
        .finally(() => {
          this.loading = false;
        });
    },

    async buscarEnderecoPorCep(cep) {
      this.loading = true;
            try {
                const response = await fetch(`https://viacep.com.br/ws/${cep}/json/`);
                const data = await response.json();
                
                if (!data.erro) {
                    this.form.endereco = data.logradouro;
                    this.form.cidade = data.localidade;
                    this.form.uf = data.uf;
                } else {
                    console.error('CEP não encontrado.');
                }
                
            } catch (error) {
                console.error('Erro ao buscar o CEP:', error);
            }
      },

        async submitForm() {
          if (!this.form.email || !this.form.senha || !this.form.cpf || !this.form.nome ||
              !this.form.celular || !this.form.dataNascimento || !this.form.cep) {

              this.notificacao_infos = {
                  variant: "error",
                  mensagem: "Preencha todos os campos obrigatórios.",
                  count_timer: 5,
              };

              window.scrollTo({
                  top: 0,
                  behavior: "smooth"
              });
          } else {
              try {
 
                  await this.buscarEnderecoPorCep(this.form.cep);
                  
                  const res = await fetch(`https://viacep.com.br/ws/${this.form.cep}/json/`);
                  const dataCEP = await res.json();

                  if (dataCEP.erro) {
                      this.loading = false;
                      this.notificacao_infos = {
                          variant: "error",
                          mensagem: "CEP inválido",
                          count_timer: 5,
                      };

                      window.scrollTo({
                          top: 0,
                          behavior: "smooth"
                      });
                  } else {
                      this.loading = true;

                      const data = {
                          ...this.form,
                          dataNascimento: new Date(this.form.dataNascimento).toISOString(),
                      };

                      api
                          .post("/usuario", data)
                              .then((response) => {
                              console.log(response.data);
                              // this.sucesso = true;
                              this.$router.push('/cadastro/confirmacao');
                          })
                          .catch((error) => {
                              console.error(error);

                              this.notificacao_infos = {
                                  variant: "error",
                                  mensagem: error.response
                                  ? error.response.data.errors[0]?.message
                                  : "Algo deu errado na requisição",
                                  count_timer: 5,
                              };

                              window.scrollTo({
                                  top: 0,
                                  behavior: "smooth"
                              });

                          })
                          .finally(() => {
                              this.loading = false;
                          });
                  }
              } catch (error) {
                  this.loading = false;
                      this.notificacao_infos = {
                          variant: "error",
                          mensagem: "CEP inválido",
                          count_timer: 5,
                      };

                      window.scrollTo({
                          top: 0,
                          behavior: "smooth"
                      });
              }
          }
      }
  },

  mounted() {

    this.rParam = this.$store.state.rParam;
    
    if (this.rParam) {
      this.form.hashIndicacao = this.rParam;
    }

    if (window.localStorage.token) {
      this.$router.push({ path: "/home"});
    } 
  },
};
</script>

<style scoped>

.confirmarSenha img {
  margin-top: 15px !important;
}

.txtcadastro {
  color: #52575a;
  font-size: 16px;
  text-align: center;
  margin: 20px 0px;
}

.copyright {
  margin-top: 50px;
}

.view {
  margin-top: -21px;
}

.btn-avancar {
  transition: background-color 0.3s ease;
  background-color: #00abc8;
  color: white;
  font-size: 16px;
  border: none;
  box-shadow: 2px 3px 9px #00000033;
  cursor: pointer;
  width: 300px;
  height: 48px;
  margin-bottom: 30px;
}

.btn-avancar:hover {
  background-color: #00b6d5;
}

.anterior {
  background-color: transparent;
  border: none;
}

.seta {
  width: 14px;
  margin-right: 15px;
  cursor: pointer;
}

.voltar {
  display: flex;
  margin: 15px 0px 30px 0px;
}

.voltar span {
  color: #52575a;
  font-weight: 200;
}

.voltar p {
  line-height: 17px;
  color: #232527;
  font-size: 14px;
  font-family: "klavika-web", sans-serif;
  font-weight: 700;
}

.linha-cinza {
  height: 1px;
  width: 300px;
  background-color: #a2a2a2;
}

.linha-etapa {
  height: 1px;
}

.etapa-senha {
  width: 100px;
  background-color: #00abc8;
}

.etapa-cpf {
  width: 200px;
  background-color: #00abc8;
}

.etapa-final {
  width: 300px;
  background-color: #00abc8;
}

.espaco {
  margin-top: 60px;
}

.txtregra {
  margin-top: 20px;
}

input {
  padding: 5px;
}

select {
  margin-bottom: 20px;
  padding: 5px;
}

.data-nasc input,
.data-nasc select {
  color: #52575a;
  font-size: 16px;
  font-family: "klavika-web-display", sans-serif;
  font-weight: 200;
}

.generos {
  margin-top: -15px;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.grupo1,
.grupo2 {
  height: 40px;
  display: flex;
  gap: 12px !important;
}

.generos label {
  color: #52575a;
  font-size: 14px;
  font-family: "klavika-web", sans-serif;
  font-weight: 400;
  margin-top: 16px;
}

.generos input {
  width: 20px !important;
  margin-bottom: 0px !important;
}

.checkbox-form label {
  color: #52575a;
  font-size: 14px;
  font-family: "klavika-web", sans-serif;
  font-weight: 400;
}

.checkbox-form input {
  margin-right: 8px;
  margin-bottom: 0px;
  top: 3px;
  position: relative;
}

.checkbox-form {
  align-items: baseline;
}

.checkbox-form {
  display: flex;
  margin-bottom: 0px;
}

#nao {
  margin-left: 14px;
}

.link-termo {
  text-decoration: underline;
  color: #52575a;
}

.margin-btn {
  margin-top: 30px;
}
/*
input[type="radio"]:checked:after {
  width: 24px;
  height: 24px;
  border-radius: 15px;
  top: 17px;
  left: -1px;
  position: relative;
  background-color: #00abc8;
  content: "";
  display: inline-block;
  visibility: visible;
  border: 2px solid white;
}

input[type="checkbox"]:checked:after {
  width: 15px;
  height: 15px;
  border-radius: 3px;
  top: 17px;
  left: -1px;
  position: relative;
  background-color: #00abc8;
  content: "";
  display: inline-block;
  visibility: visible;
  border: 2px solid white;
}
*/
.btn-disabled {
  background-color: #03a5c14a !important;
  cursor: auto;
}

.regras {
  margin-bottom: 20px;
}

.regra-valida {
  color: #00abc8;
  margin-bottom: 5px;
}

.regra-invalida {
  margin-bottom: 5px;
  color: #e20000;
}

.btn-senha {
  margin-top: 30px;
}

.campo-senha .view {
  margin-top: -14px;
}

.form-login .label {
  margin-top: 20px;
}

#dataNascimento {
  width: 100% !important;
  font-weight: 600 !important;
  color: black !important;
  font-family: "klavika-web", sans-serif !important;
}

.erro {
    font-size: 14px;
    color: #d30000;
}

.msgCpf {
  color: #ef5257;
  background: rgba(239, 82, 87, 0.1);
  width: 100%;
  padding: 10px 10px;
  border-radius: 10px;
  font-size: 14px;
  font-weight: 600;
  margin: 10px 0;
  margin-bottom: 15px;
  display: inline-block;
  text-align: center;
}

.data-nasc {
    width: 100%;
    max-width: 100%;
    margin-bottom: 20px;
}

 input[type="date"] {
    width: 100%;
    max-width: 100%;
}

.txt-termos {
    margin-top: 25px;
    color: #52575a !important;
    font-size: 12px;
    font-family: "klavika-web", sans-serif;
    font-weight: 400;
    
}

.txt-termos a {
  color: #52575a !important;
  text-decoration: underline;
}

 .label-genero {
   margin-bottom: 0px;
}

.sucesso {
    height: 100vh;
    background-color: #D4D1C4;
}

.sucesso h1 {
  color: #FFFFFF;
  font-size: 32px;
  font-family: "klavika-web-display", sans-serif;
  font-weight: 200;
  line-height: 35px;
  margin-bottom: 20px;
}

.sucesso strong {
  color: #FFFFFF;
  font-family: "klavika-web", sans-serif;
  font-weight: 700;
}

.sucesso p {
  color: #FFFFFF;
  font-size: 16px;
  font-family: "klavika-web", sans-serif;
  font-weight: 400;
}

.conteudo-sucesso {
    width: 300px;
    margin: 0 auto;
    padding-top: 120px;
}

.btn-login {
  transition: background-color 0.3s ease;
  background-color: #00ABC8;
  color: white;
  font-size: 16px;
  border: none;
  box-shadow: 2px 3px 9px #00000033;
  cursor: pointer;
  width: 300px;
  height: 48px;
  margin: 30px auto;
  display: block;
}

.btn-login:hover {
  background-color: #00b6d5;
}
.sucesso {
    background-image: url('@/assets/img/BG_SUCESSO_LOGIN.png');
    background-size: cover;
    background-repeat: no-repeat;
  }

@media (min-width: 991px) {
  .form-login {
    min-height: 580px;
  }
  
  .mob {
    display: none;
  }
}
@media (max-width: 990px) {
  .desk {
    display: none;
  }
  .sucesso {
    background-image: url('@/assets/img/bg-sucesso.png');
    background-size: cover;
    background-repeat: no-repeat;
  }

  .logo {
    padding-top: 40px;
    margin: 0 auto;
  }

  .conteudo-sucesso {
    padding: 0px 30px;
    width: 100%;
    position: absolute;
    bottom: 0px;
  }

  .btn-login {
    margin: 30px 0px;
  }

    input[type="radio"]:checked:after  {
        top: 14px;
    }

  .login .logo {
    padding-top: 40px;
  }

  .login h1 {
    margin: 20px 0px;
  }

  .txtcadastro {
    margin: 30px 0px;
  }

  .copyright {
    margin-bottom: 30px;
  }
}
</style>