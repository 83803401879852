<template>
  <div>
  
      <section class="sucesso">
           <img
                  src="@/assets/img/logo_legionarios_branca.svg"
                  alt="Logo Stanley Legionários"
                  class="logo desk"
          />
  
          <img
                  src="@/assets/img/logo_legionarios_preto.svg"
                  alt="Logo Stanley Legionários"
                  class="logo mob"
          />
          <div class="conteudo-sucesso" data-anima="bottom">
            <h1>INSCRIÇÃO REALIZADA COM <strong>SUCESSO!</strong></h1>
            <p>Enviamos um e-mail de confirmação, verifique sua caixa postal e comece a explorar.</p>
  
            <router-link to="/login" style="text-decoration: none;" >
              <button type="button" class="btn-login">LOGIN</button>
            </router-link>
          </div>
      </section>
  
  </div>
  
  </template>
  
  <script>
  export default{
      name: 'CadastroConfirmacao'
  }
  </script>
  
  
  <style scoped>
  
  .sucesso {
      height: 100vh;
      background-color: #D4D1C4;
  }
  
  .sucesso h1 {
    color: #FFFFFF;
    font-size: 32px;
    font-family: "klavika-web-display", sans-serif;
    font-weight: 200;
    line-height: 35px;
    margin-bottom: 20px;
  }
  
  .sucesso strong {
    color: #FFFFFF;
    font-family: "klavika-web", sans-serif;
    font-weight: 700;
  }
  
  .sucesso p {
    color: #FFFFFF;
    font-size: 16px;
    font-family: "klavika-web", sans-serif;
    font-weight: 400;
  }
  
  .conteudo-sucesso {
      width: 300px;
      margin: 0 auto;
      padding-top: 120px;
  }
  
  .btn-login {
    transition: background-color 0.3s ease;
    background-color: #00ABC8;
    color: white;
    font-size: 16px;
    border: none;
    box-shadow: 2px 3px 9px #00000033;
    cursor: pointer;
    width: 300px;
    height: 48px;
    margin: 30px auto;
    display: block;
  }
  
  .btn-login:hover {
    background-color: #00b6d5;
  }
  
  
  
  @media (min-width: 991px) {
    .form-login {
      min-height: 580px;
    }
    
    .mob {
      display: none;
    }
  }
  @media (max-width: 990px) {
    .desk {
      display: none;
    }
    .sucesso {
      background-image: url('@/assets/img/bg-sucesso.png');
      background-size: cover;
      background-repeat: no-repeat;
    }
  
    .logo {
      padding-top: 40px;
      margin: 0 auto;
    }
  
    .conteudo-sucesso {
      padding: 0px 30px;
      width: 100%;
      position: absolute;
      bottom: 0px;
    }
  
    .btn-login {
      margin: 30px 0px;
    }
  
      input[type="radio"]:checked:after  {
          top: 14px;
      }
  
    .login .logo {
      padding-top: 40px;
    }
  
    .login h1 {
      margin: 20px 0px;
    }
  
    .txtcadastro {
      margin: 30px 0px;
    }
  
    .copyright {
      margin-bottom: 30px;
    }
  }
  
  .sucesso {
      background-image: url('@/assets/img/BG_SUCESSO_LOGIN.png');
      background-size: cover;
      background-repeat: no-repeat;
    }
  
  </style>