<template>
    <div>
        <div class="cupom">
            <img :src="dados[0].urlImagem" alt="Imagem do cupom" />
            <div :class="{
                'bloqueado': dados[0].esgotado || !dados[0].podeResgatar,
                'resgatado': dados[0].jaResgatado && !dados[0].podeResgatar,
                'nao-resgatado': dados[0].podeResgatar && !dados[0].esgotado,
                'utilizado': dados[0].resgates.length > 0 && dados[0].resgates[0].usoCupom && !dados[0].multiploResgate
            }">

                <div class="d-flex ingressos">
                    <p class="txt-cupom">{{ texto }}</p>
                    <img v-if="!dados[0].evento" src="@/assets/img/tickets.png" alt="Imagem de ticket" class="ticket" />
                </div>

                <div class="box-indicacoes" v-if="dados[0].valor && dados[0].valor > 0">
                    <div class="d-flex qtd-indicacoes">
                        <h3>{{ dados[0].valor }}</h3>
                        <img src="@/assets/img/icone-branco.png" alt="Imagem de favicon" class="icone-indicacao" />
                    </div>
                    <p class="txt-indicacaoes">INDICAÇÕES</p>
                </div>
            </div>

        </div>

        <div class="btn-cupom">


            <!-- <button type="button" @click="openModal()" id="resgatarCupom">RESGATAR!</button> -->

            <div>
                <div v-if="dados[0].jaResgatado && !dados[0].podeResgatar">

                    <div class="btn-cupom">
                        <div v-if="dados[0].resgates[0].usoCupom && !dados[0].multiploResgate">
                            <button type="button" class="btn-bloqueado resgatado btn-utilizado">UTILIZADO! </button>
                        </div>
                        <div v-else>
                            <button v-if="!dados[0].evento" type="button" class="btn-bloqueado resgatado"
                                @click="openModal()">VER DETALHES! </button>
                            <button v-else type="button" class="btn-bloqueado resgatado"
                                @click="openModal()">VER CUPOM! </button>

                        </div>


                        <div v-if="dados[0].resgates[0].usoCupom && !dados[0].multiploResgate">
                            <div v-for="(cupom, index) in cupons" :key="index">
                                <div>
                                    <h3 v-if="(cupom.idBeneficio == dados[0].id) && cupom.dataUsoCupom"
                                        class="usoCupom">CUPOM
                                        UTILIZADO EM: {{ formatarData(cupom.dataUsoCupom) }}</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-else>
                    <div>
                        <div class="btn-cupom" v-if="dados.every(item => item.esgotado) ">
                            <button type="button" class="btn-bloqueado" disabled>ESGOTADO! <img
                                    src="@/assets/img/cadeado.png" alt="Icone de copiar" /></button>
                        </div>

                        <div class="btn-cupom" v-else-if="!dados[0].podeResgatar">
                            <button type="button" class="btn-bloqueado" disabled>RESGATAR! <img
                                    src="@/assets/img/cadeado.png" alt="Icone de copiar" /></button>
                        </div>

                        <div class="btn-cupom" v-else>
                            <button type="button" @click="openModal()" id="resgatarCupom"
                                :disabled="!usuarioLogado.perfilCompleto || !usuarioLogado.perfilDefinido">RESGATAR!</button>
                        </div>
                    </div>

                </div>
            </div>

            <b-progress v-if="!usuarioLogado.colaborador && dados[0].valor && dados[0].valor > 0"
                                        :value="progresso()" max="100" class="barra-progresso"></b-progress>


        </div>

    </div>
</template>


<script>
import { api } from '@/services';

export default {
    name: 'BeneficioUnificado',
    data() {
        return {
            usuarioLogado: []
        }
    },
    props: {
        dados: {
            type: Array,
            riquired: false,
        },
        texto: {
            type: String,
            riquired: false,
        },
        saldoindicacao:{
            type: Number,
            riquired: false,
        }

    },
    methods: {
        progresso() {
      return (this.saldoindicacao / this.dados[0].valor) * 100;
    },
        openModal() {
            this.$emit('open-unificado', this.dados[0])
        },
        buscarUsuarios() {
      this.loading = true;
      api
        .get('/usuario/logged')
        .then((resp) => {
          this.usuarioLogado = resp.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    },
    mounted() {
        this.buscarUsuarios()
    }
}
</script>



<style>
.ingressos {
    align-items: flex-start;
}

.cupom {
    width: 308px;
    background-color: #52575A85;
    border: 3px solid #FFFFFF;
    padding: 10px;
}
.btn-bloqueado {
  border: 1px solid #848484 !important;
  color: #848484 !important;
  background-color: #52575A !important;
}
.txt-cupom {
    color: white;
    text-transform: uppercase;
    font-size: 28px;
    line-height: 31px;
    font-family: "KNOCKOUT-HTF49-LITEWEIGHT";
    min-height: 109px;
}

.bloqueado {
    background-color: #52575A;
    line-height: 32px;
    padding: 15px;
    min-height: 250px;
    border: 1px solid #848484 !important;
    align-items: start;
}

.resgatado {
    color: white !important;
    background-color: #407BBF !important;
}

.icone-indicacao {
    width: 50px;
}

.resgatado {
  color: white !important;
  background-color: #407BBF !important;
}

.nao-resgatado {
    background-color: #FF681E !important;
    line-height: 32px;
    padding: 15px;
    min-height: 250px;
    align-items: start;
}

.qtd-indicacoes {
    gap: 5px;
    align-items: center;
    justify-content: end;
}

.qtd-indicacoes h3 {
    color: white;
    font-family: "KNOCKOUT-HTF49-LITEWEIGHT";
    font-size: 50px;
    margin-bottom: 0px;
    margin-top: 5px;
}

.txt-indicacaoes {
    text-align: right;
    margin-bottom: 0px !important;
    color: white;
    margin-top: -10px;
    font-size: 25px;
    font-family: "KNOCKOUT-HTF49-LITEWEIGHT";
}

.barra-progresso {
  margin-top: 10px;
}

.btn-cupom button,
.conteudo-modal button {
    align-items: center;
    display: flex;
    justify-content: center;
    gap: 10px;
    font-family: "KNOCKOUT-HTF49-LITEWEIGHT";
    margin-top: 20px;
    background-color: #FF681E;
    font-size: 32px;
    letter-spacing: 1px;
    border: none;
    color: white;
    height: 55px;
    width: 100%;
}

.btn-utilizado {
  background-color: #6F8473 !important;
  cursor: default !important;
}


.utilizado {
    background-color: #6F8473 !important;
}

.ticket {
    width: 35px;
}

@media (max-width: 990px) {

    .cupom {
        width: 300px;
        margin-bottom: 20px;

    }

    .btn-cupom button {
        margin-top: 0px;
    }

}

.usoCupom {
  font-family: "klavika medium";
  margin-top: 10px;
  text-align: center;
  letter-spacing: 0.5px;
  color: white;
  font-size: 15px;
}
</style>